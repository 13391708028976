import {ReduxState} from 'ra-core'

export interface IRequestData {
    url: string
    method?: 'POST' | 'PUT' | 'DELETE' | 'GET'
    data?: any
    token?: string
    host?: string
}

export interface IResponse {
    data: any
    err: any
}

export interface BaseAction {
    type: string
    payload: any
}

export type ThemeName = 'light' | 'dark'

export interface AppState extends ReduxState {

}

export enum AdminRole {
    SuperAdmin = 'superAdmin',
    Admin = 'admin',
    AccountAdmin = 'adminAccount',
    AccountManager = 'managerAccount',
}

export const AdminRoleList = [
    {id: AdminRole.SuperAdmin, name: 'СуперАдмин'},
    {id: AdminRole.Admin, name: 'Администратор'},
    {id: AdminRole.AccountAdmin, name: 'Админ партнера'},
    {id: AdminRole.AccountManager, name: 'Менеджер партнера'}
]

export interface IUser {
    id: number
    name: string
}

export enum CountryCode {
    Ru = 'ru'
}


export const PushTypes = [
    {id: 'admin', name: 'Общее уведомление'},
    {id: 'userRegistered', name: 'Пользователь зарегистрирован'}
]
export const PushNotificationChannel = [
    {id: 'push', name: 'Push'},
    {id: 'email', name: 'Email'},
    {id: 'alert', name: 'Уведомление'}
]
export const PushTargetTypes = [
    {id: 'all', name: 'Всем'},
    {id: 'user', name: 'Пользователю'}
]
export const PushStatuses = [
    {id: 'created', name: 'Черновик'},
    {id: 'toSent', name: 'Отправить'},
]
export const PushPriorities = [
    {id: 'high', name: 'Высокий'},
    {id: 'normal', name: 'Обычный'},
]
export const PushSoundTypes = [
    {id: '', name: 'Отключен'},
    {id: 'default', name: 'Включен'},
]

export enum FeedbackStatus {
    Moderation = 'moderation',
    Approved = 'approved',
    Rejected = 'rejected',
}

export const FeedbackStatusList = [
    {id: FeedbackStatus.Moderation, name: 'Модерация'},
    {id: FeedbackStatus.Approved, name: 'Запрувлен'},
    {id: FeedbackStatus.Rejected, name: 'Отклонен'},
]

export const FeedbackMarkList = [
    {id: 0, name: '0'},
    {id: 1, name: '2'},
    {id: 2, name: '3'},
    {id: 3, name: '3'},
    {id: 4, name: '4'},
    {id: 5, name: '5'},
]


export enum ReservationType {
    Table = 'table',
    Banquet = 'banquet',
}

export const ReservationTypeList = [
    {id: ReservationType.Table, name: 'Стол'},
    {id: ReservationType.Banquet, name: 'Банкет'}
]


export enum ReservationStatus {
    New = 'new',
    Approved = 'approved',
    Rejected = 'rejected',
}

export const ReservationStatusList = [
    {id: ReservationStatus.New, name: 'Новый'},
    {id: ReservationStatus.Approved, name: 'Одобрен'},
    {id: ReservationStatus.Rejected, name: 'Отклонен'}
]


export enum ArticleType {
    News = 'news',
    Article = 'article',
    EntityList = 'entityList',
}

export enum ArticleEntityListType {
    Events = 'events',
    Restaurants = 'restaurants',
    Entertainment = 'entertainments',
    Property = 'properties',
    Boat = 'boats',
    Hotel = 'hotels',
}

export const ArticleTypeList = [
    {id: ArticleType.News, name: 'Новость'},
    {id: ArticleType.Article, name: 'Статья'},
    {id: ArticleType.EntityList, name: 'Список'}
]
export const ArticleEntityListTypeList = [
    {id: ArticleEntityListType.Restaurants, name: 'Рестораны'},
    {id: ArticleEntityListType.Entertainment, name: 'Экскурсии'},
    {id: ArticleEntityListType.Events, name: 'События'},
    {id: ArticleEntityListType.Property, name: 'Недвижимость'},
    {id: ArticleEntityListType.Hotel, name: 'Гостиницы'},
    {id: ArticleEntityListType.Boat, name: 'Лодки'}
]

export const ArticleGroupTypeList = [
    {id: 'lifestyle', name: 'LifeStyle'},
    {id: 'business', name: 'Бизнес'},
    {id: 'news', name: 'Новости'}
]

export enum AverageBillType {
    Low = 1,
    Normal = 2,
    Expensive = 3,
}

export const AverageBillTypeList = [
    {id: AverageBillType.Low, name: '₽'},
    {id: AverageBillType.Normal, name: '₽₽'},
    {id: AverageBillType.Expensive, name: '₽₽₽'}
]

export enum BannerType {
    UnSkippable = 'unSkippable',
    Top = 'top',
}

export const BannerTypeList = [
    {id: BannerType.UnSkippable, name: 'Не скрываемый'},
    {id: BannerType.Top, name: 'Верхний'},
]

export enum BannerViewType {
    Custom = 'custom',
    Events = 'events',
    Restaurants = 'restaurants',
    Entertainment = 'entertainments',
    Property = 'properties',
    Boat = 'boats',
    Hotel = 'hotels',
}

export const BannerViewTypeList = [
    {id: BannerViewType.Custom, name: 'Кастомный'},
    {id: BannerViewType.Restaurants, name: 'Ресторан'},
    {id: BannerViewType.Entertainment, name: 'Экскурсия'},
    {id: BannerViewType.Events, name: 'Событие'},
    {id: BannerViewType.Property, name: 'Недвижимость'},
    {id: BannerViewType.Hotel, name: 'Гостиница'},
    {id: BannerViewType.Boat, name: 'Лодка'}
]

export enum PropertyType {
    Boat = 'boat',
    Transport = 'transport',
    Property = 'property',
    Hotel = 'hotel',
}


export enum RestaurantNewsType {
    News = 'news',
    Article = 'article'
}

export const RestaurantNewsTypeList = [
    {id: ArticleType.Article, name: 'Статья'},
    {id: ArticleType.News, name: 'Новость'},

]

export interface ModuleSettingsOption {
    id: string
    name: string
    type: 'number' | 'text'
    defaultValue: string | number,
    description: string
}

export interface ModuleSettings {
    id: string
    name: string
    description: string
    settings: ModuleSettingsOption[]
}


export enum CasinoGameType {
    Baccarat = 'baccarat',
    Blackjack = 'blackjack',
    Coinflip = 'coinflip',
    Crash = 'aviator',
    Diamonds = 'diamonds',
    Dice = 'dice',
    HiLo = 'hilo',
    Keno = 'keno',
    Limbo = 'limbo',
    Mines = 'mines',
    Plinko = 'plinko',
    Roulette = 'roulette',
    Stairs = 'stairs',
    Tower = 'tower',
    VideoPoker = 'minipoker',
    Wheel = 'wheel',
    Chess = 'chess'
}

export const CasinoGameTypeList = [
 //   {id: CasinoGameType.Baccarat, name: 'Baccarat'},
    {id: CasinoGameType.Blackjack, name: 'Blackjack'},
    {id: CasinoGameType.Coinflip, name: 'Coin flip'},
    {id: CasinoGameType.Crash, name: 'Craft'},
    {id: CasinoGameType.Diamonds, name: 'Diamonds'},
    {id: CasinoGameType.Dice, name: 'Dice'},
    {id: CasinoGameType.HiLo, name: 'HiLo'},
    {id: CasinoGameType.Keno, name: 'Keno'},
    {id: CasinoGameType.Limbo, name: 'Limbo'},
    {id: CasinoGameType.Mines, name: 'Mines'},
    {id: CasinoGameType.Plinko, name: 'Plinko'},
    {id: CasinoGameType.Roulette, name: 'Roulette'},
    {id: CasinoGameType.Stairs, name: 'Stairs'},
    {id: CasinoGameType.Tower, name: 'Tower'},
    {id: CasinoGameType.VideoPoker, name: 'Video Poker'},
    {id: CasinoGameType.Wheel, name: 'Wheel'},
    {id: CasinoGameType.Chess, name: 'Chess'},
]

export enum CasinoGameRoundStatus {
    InProgress = 'inProgress',
    Win = 'win',
    Lose = 'lose',
    Cancelled = 'cancelled'
}

export enum CasinoGameRoundType {
    Quick = 'quick',
    Extended = 'extended'
}

export const CasinoGameRoundStatusList = [
    {id: CasinoGameRoundStatus.InProgress, name: 'В процессе'},
    {id: CasinoGameRoundStatus.Win, name: 'Победа'},
    {id: CasinoGameRoundStatus.Lose, name: 'Проигрыш'},
    {id: CasinoGameRoundStatus.Cancelled, name: 'Отмена'}
]

export const CasinoGameRoundTypeList = [
    {id: CasinoGameRoundType.Quick, name: 'Baccarat'},
    {id: CasinoGameRoundType.Extended, name: 'Blackjack'}
]

export enum TransactionType {
    Bet = 'bet',
    RefundBet = 'refundBet',
    Payout = 'payout',
    RefundPayout = 'refundPayout',
}

export const TransactionTypeList = [
    {id: TransactionType.Bet, name: 'Ставка'},
    {id: TransactionType.RefundBet, name: 'Возврат ставки'},
    {id: TransactionType.Payout, name: 'Выплата'},
    {id: TransactionType.RefundPayout, name: 'Возврат выплаты'},
]

export enum UserTransactionStatus {
    Pending = "pending",
    Completed = "completed",
}

export const TransactionStatusList = [
    {id: UserTransactionStatus.Pending, name: 'В процессе'},
    {id: UserTransactionStatus.Completed, name: 'Выполнена'}
]

export enum PartnerApiErrorType {
    Auth = 'auth',
    NoMoney = 'noMoney',
    Processed = 'processed',
    Format = 'format',
    UnKnown = 'unknown',
    NotDelivered = 'notDelivered'
}

export const PartnerApiErrorTypeList = [
    {id: PartnerApiErrorType.Auth, name: 'Авторизация'},
    {id: PartnerApiErrorType.NoMoney, name: 'Недостаточно средств'},
    {id: PartnerApiErrorType.Processed, name: 'Уже выполнена'},
    {id: PartnerApiErrorType.Format, name: 'Формат'},
    {id: PartnerApiErrorType.UnKnown, name: 'Неизвестно'},
    {id: PartnerApiErrorType.NotDelivered, name: 'Недоставлено'},
]

export enum AviatorGameRoundStatus {
    Created = 'created',
    InProgress = 'inProgress',
    Finished = 'finished'
}

export const AviatorGameRoundStatusList = [
    {id: AviatorGameRoundStatus.Created, name: 'Создан'},
    {id: AviatorGameRoundStatus.InProgress, name: 'В процессе'},
    {id: AviatorGameRoundStatus.Finished, name: 'Завершено'},
]


export enum ChessGameBoardStatus {
    Waiting = 'waiting',
    InProgress = 'inProgress',
    FinishedByKingCapture = 'finishedByKingCapture',
    FinishedByDraw = 'finishedByDraw',
    FinishedByGiveUp = 'finishedByGiveUp',
    FinishedByTimeout = 'finishedByTimeout',
    WaitingAccept = 'waitAccept',
    Cancelled = 'cancelled',
}

export const ChessGameBoardStatusList = [
    {id: ChessGameBoardStatus.Waiting, name: 'Ожидание'},
    {id: ChessGameBoardStatus.InProgress, name: 'В процессе'},
    {id: ChessGameBoardStatus.FinishedByKingCapture, name: 'Выигрыш - Мат'},
    {id: ChessGameBoardStatus.FinishedByDraw, name: 'Ничья'},
    {id: ChessGameBoardStatus.FinishedByGiveUp, name: 'Победа - сдался'},
    {id: ChessGameBoardStatus.FinishedByTimeout, name: 'Победа - вышло время'},
    {id: ChessGameBoardStatus.WaitingAccept, name: 'Ожидание подтвреждения'},
    {id: ChessGameBoardStatus.Cancelled, name: 'Отменено'},
]

export enum CasinoGameSettingsOptionType {
    Text = 'text',
    Number = 'number'
}

export interface ICasinoGameSettings {
    id: string;
    name: string;
    description: string;
    defaultValue: string | number;
    type: CasinoGameSettingsOptionType;
}

export interface ICasinoGame extends Record<any, any> {
    id: string,
    name: string,
    type: CasinoGameType,
    modules: ModuleSettings[]
}

export enum CasinoGameTestStatus {
    Created = 'created',
    InQueue = 'inQueue',
    InProgress = 'inProgress',
    Finished = 'finished',
    Cancelled = 'cancelled',
    Error = 'error',
}

export const CasinoGameTestStatusList = [
    {id: CasinoGameTestStatus.Created, name: 'Создан'},
    {id: CasinoGameTestStatus.InQueue, name: 'В очереди'},
    {id: CasinoGameTestStatus.InProgress, name: 'В процессе'},
    {id: CasinoGameTestStatus.Finished, name: 'Завершен'},
    {id: CasinoGameTestStatus.Cancelled, name: 'Отменен'},
    {id: CasinoGameTestStatus.Error, name: 'Ошибка'}
]

export enum CasinoGameTypeTestStatus {
    Created = 'created',
    InQueue = 'inQueue',
    InProgress = 'inProgress',
    Finished = 'finished',
    Cancelled = 'cancelled',
    Error = 'error',
}

export const CasinoGameTypeTestStatusList = [
    {id: CasinoGameTypeTestStatus.Created, name: 'Создан'},
    {id: CasinoGameTypeTestStatus.InQueue, name: 'В очереди'},
    {id: CasinoGameTypeTestStatus.InProgress, name: 'В процессе'},
    {id: CasinoGameTypeTestStatus.Finished, name: 'Завершен'},
    {id: CasinoGameTypeTestStatus.Cancelled, name: 'Отменен'},
    {id: CasinoGameTypeTestStatus.Error, name: 'Ошибка'}
]

export enum CasinoGameTestStrategy {
    Random = 'random',
    MartinGail = 'martinGail',
    MartinGailReverse = 'martinGailReverse',
}

export const CasinoGameTestStrategyList = [
    {id: CasinoGameTestStrategy.Random, name: 'Рандом'},
    {id: CasinoGameTestStrategy.MartinGail, name: 'Мартин Гейл'},
    {id: CasinoGameTestStrategy.MartinGailReverse, name: 'Мартин Гейл Reverse'}
]

export enum CasinoGameTestDifficulty{
    None = 'none',
    Low = 'low',
    Middle = 'middle',
    High = 'high',
    SuperHigh = 'superHigh'
}

export const CasinoGameTestDifficultyList = [
    {id: CasinoGameTestDifficulty.None, name: 'Не важно (не выше среднего)'},
    {id: CasinoGameTestDifficulty.Low, name: 'Легко'},
    {id: CasinoGameTestDifficulty.Middle, name: 'Средне'},
    {id: CasinoGameTestDifficulty.High, name: 'Сложно'},
    {id: CasinoGameTestDifficulty.SuperHigh, name: 'Супер сложно'},
]

export const LanguageList = [
    {name: 'Ru', id: 'ru'},
    {name: 'En', id: 'en'},
    {name: 'Uz', id: 'uz'},
    {name: 'Az', id: 'az'},
    {name: 'Tr', id: 'tr'},
    {name: 'Hi', id: 'hi'},
    {name: 'Fa', id: 'fa'},
    {name: 'Uk', id: 'uk'},
    {name: 'Kk', id: 'kk'},
    {name: 'Es', id: 'es'},
    {name: 'Fr', id: 'fr'},
    {name: 'Hy', id: 'hy'},
    {name: 'Br', id: 'br'},
    {name: 'Th', id: 'th'},
    {name: 'Vn', id: 'vn'},
    {name: 'Mx', id: 'es-MX'},
    {name: 'Cl', id: 'es-CL'},
    {name: 'Pe', id: 'es-PE'},
    {name: 'Pt', id: 'pt'},
    {name: 'Be', id: 'be'},
    {name: 'Cz', id: 'cz'},
    {name: 'Pl', id: 'pl'},
    {name: 'Ro', id: 'ro'},
    {name: 'Bn', id: 'bn'},
    {name: 'Hu', id: 'hu'},
    {name: 'Fi', id: 'fi'},
    {name: 'Ne', id: 'ne'},
    {name: 'Sw', id: 'sw'},
    {name: 'De', id: 'de'},
    {name: 'It', id: 'it'},
]
