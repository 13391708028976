import React, {useEffect, useState} from 'react';
import {
  BooleanInput,
  required,
  TextInput, useDataProvider, useRefresh,NumberInput,
  ArrayInput, SimpleFormIterator, ReferenceInput, AutocompleteInput, SelectInput, FormTab
} from "react-admin";

import ModalForm from 'src/components/ModalForm'
import {getTranslation} from 'src/utils/translation'
import {ModuleSettingsCard} from 'src/components/inputs/GameModuleSettingsCard'
import {ICasinoGame} from 'src/types'

export const CasinoGameTestTypeForm = (props) => {

  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const [record, setRecord] = useState(null)
  const [settings, setSettings] = useState(null)
  console.log("CasinoGameTestTypeForm", props)
  useEffect(() => {
    console.log("LoadData111")
    if(props.isNew) {

    }else{
      dataProvider.getOne('casino-game-type-test', {id: props.record.id}).then(i => {
        setRecord(i.data)
        console.log("SetRecord", i.data.modules)
        setSettings(i.data.modules)
      })
    }
  }, [])
  const handleSubmit = async data => {
    if(props.isNew) {
      await dataProvider.create('casino-game-type-test', {
        data: {
          ...data,
          userId: record.id
        }
      });
    }else{
      await dataProvider.update('casino-game-type-test', {id: record.id, data, previousData: record});
    }
    refresh();
    return true;
  };

  return <ModalForm
    {...props}
    title={props.isNew ? 'Добавить тест игры' : `Изменить тест игры ${props.record?.game?.name ?? props.record?.game?.type}`}
    handleClose={props.onClose}
    save={handleSubmit}
    fullWidth={true}
    record={record}
  >

    <BooleanInput source="enabled" label={'Включено'} variant={'outlined'} defaultValue={true}/>
    {record && (settings || []).map(i => <ModuleSettingsCard formKey={`gameSettings.${i.id}`}  module={i}/>)}


  </ModalForm>
}
