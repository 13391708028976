import * as React from 'react';
import {FC} from 'react';
import {
  Filter,
  List,
  TextInput,
  ReferenceArrayInput,
  AutocompleteInput,
  ReferenceInput,
  ListProps,
  FunctionField,
  AutocompleteArrayInput,
  BooleanField,
  ArrayField,
  Datagrid,
  ReferenceField,
  ChipField,
  SingleFieldList,
  SelectInput,
  TextField,
  SelectField,
  BooleanInput, DateField, SimpleForm
} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {csvExporter, formatCsvDate, formatListValue} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import VisibilityField from 'src/components/fields/VisibilityField'
import {
  AdminRole, AdminRoleList, AviatorGameRoundStatusList, CasinoGameRoundStatus,
  CasinoGameTypeList, ChessGameBoardStatusList,
  PartnerApiErrorTypeList,
  TransactionStatusList,
  TransactionTypeList
} from 'src/types'
import AssetField from 'src/components/fields/AssetField'
import LinkField from 'src/components/fields/LinkField'
import ProfitField from 'src/components/fields/ProfitField'
import {formatAmount} from 'src/utils/formatters'
import DateRangeInput from "src/components/inputs/DateRangeInput";

const exporter = posts => {
  return csvExporter({
    columns: {
      'id': 'ID',
      'status': 'Статус',
      'multiplier': 'Множитель',
      'totalPlayers': 'Кол-во игрок',
      'createdAt': 'Создан',
      'plannedStartAt': 'Планируемый старт',
      'startedAt': 'Старт факт',
      'finishedAt': 'Завершен',

    },
    data:  posts.map(item => ({
      ...item,
      status: formatListValue(item.status, AviatorGameRoundStatusList),
      createdAt:  formatCsvDate(item.createdAt),
      plannedStartAt:  formatCsvDate(item.plannedStartAt),
      startedAt:  formatCsvDate(item.startedAt),
      finishedAt:  formatCsvDate(item.finishedAt),
    })),
    fileName: 'CasinoGame'
  })
};

const _Filter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'} alwaysOn={true} />
    <SelectInput source="status" label={'Статус'} choices={AviatorGameRoundStatusList} variant={'outlined'}/>
    <DateRangeInput source={'createdAt'} sourceStart={'createdAt||$gte'} sourceEnd={'createdAt||$lte'} label="Даты"  alwaysOn={true} />
    <BooleanInput source="isDemo" label={'Демо'} variant={'outlined'} resettable={true}  alwaysOn={true}/>

  </Filter>
)

const ChessGameBoardList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={exporter}
      bulkActionButtons={false}
      filters={<_Filter/>}
      title="Шахматы партии"
      filterDefaultValues={{ isDemo: false }}
      sort={{field: 'createdAt', order: 'DESC'}}
      empty={<EmptyList title={'Нет партий'} />}
    >
    <Datagrid>
      {(props.permissions === AdminRole.SuperAdmin || props.permissions === AdminRole.Admin)  && <ReferenceField label="Партнер" source="accountId" reference="account">
          <TextField source="name"  />
      </ReferenceField>}
      <TextField source={'id'} label={'ID'}/>

     <LinkField source={'ownerUser.partnerUserId'} link={`/user/$id`} label={'ID 1'} />
      <LinkField source={'opponentUser.partnerUserId'} link={`/user/$id`} label={'ID 2'} />
      <SelectField source="status" label={'Статус'} choices={ChessGameBoardStatusList}/>
      <ProfitField source={'betAmount'} label={'Сумма ставки'} value={(i) => (i.betAmount * 2) * i.usdRate}  currency={'USD'} type={'bet'}/>

      <FunctionField source={'accountCommission'} label={'Маржа'} render={(i) => `${i.accountCommission}%`}/>
      <ProfitField value={(i) => i.winnerUserId === i.ownerUserId ? i.winAmount * i.usdRate : -1 * i.loseAmount * i.usdRate} currency={'USD'} label={'Сумма игрок 1'}/>
      <ProfitField value={(i) => i.winnerUserId === i.opponentUserId ? i.winAmount * i.usdRate : -1 * i.loseAmount * i.usdRate}  currency={'USD'} label={'Сумма игрок 2'}/>

      <ProfitField source={'serviceCommissionAmount'} label={'GGR'} value={(i) => i.serviceCommissionAmount * i.usdRate} currency={'USD'} type={'bet'}/>
      {(props.permissions === AdminRole.SuperAdmin) &&
      <FunctionField source={'accountCommission'} label={'Маржа с партнера'} render={(i) => `${i.serviceCommission}%`}/>
      }
      {(props.permissions === AdminRole.SuperAdmin) &&
      <ProfitField source={'serviceCommissionAmount'} label={'Прибыль с партнера'} value={(i) => i.serviceCommissionAmount * i.usdRate}
                   currency={'USD'} type={'bet'}/>
      }
      <DateField source="createdAt" label={'Создан'} showTime={true} />
     </Datagrid>
    </List>
  );
}

export default ChessGameBoardList;
