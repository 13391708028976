import React, {useState} from 'react';
import {
  BooleanInput,
  required,
  TextInput, useDataProvider, useRefresh,
  ArrayInput, SimpleFormIterator, ReferenceInput, AutocompleteInput, Confirm, useNotify
} from "react-admin";


import {Button, Typography} from '@material-ui/core'
import {CasinoGameTestStatus, CasinoGameTestStatusList} from 'src/types'

export const CasinoGameTestNameField = (props) => {

  const record = props.record ?? props.data;
  const progress = (() => {
    if([CasinoGameTestStatus.InProgress, CasinoGameTestStatus.Cancelled, CasinoGameTestStatus.Finished].includes(record.status)){
      if(!record.totalTestsFinished && !record.totalTests){
        return 0
      }
      return Math.floor((record.totalTestsFinished / record.totalTests) * 100)
    }else{
      return null
    }
  })();
  return <div {...props}>
    {record.name && <Typography variant="inherit" >{record.name}</Typography>}
    {record.name && <br/>}
    <div style={{whiteSpace: 'nowrap'}}>
      <Typography variant="inherit">{CasinoGameTestStatusList.find(i => i.id === record.status)?.name}</Typography>
      {progress > 0 && progress !== 100 && <Typography variant="inherit" color={'textSecondary'}> {progress}%</Typography>}

    </div>



  </div>
}
