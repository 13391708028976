import * as React from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  useTranslate,
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
} from 'react-admin';

import accounts from 'src/resources/account'
import administrators from 'src/resources/administrators'
import user from 'src/resources/user'
import page from 'src/resources/page'
import asset from 'src/resources/assets'
import account from 'src/resources/account'
import casinoGame from 'src/resources/casino-game'
import casinoGameAccount from 'src/resources/casino-game-account'
import casinoGameRound from 'src/resources/casino-game-round'
type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers' | 'menuMarketing' | 'menuSettings' | 'menuMarketing';

const Menu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    menuCatalog: true,
    menuSales: true,
    menuCustomers: true,
    menuMarketing: true,
    menuSettings: true,

  });
  const translate = useTranslate();
  const classes = useStyles();

  const handleToggle = (menu: MenuName) => {
    setState(state => ({ ...state, [menu]: !state[menu] }));
  };
  //@ts-ignore
  return (

    <div className={classes.root}>

      {/* @ts-ignore */}
      <MenuItemLink
        to={`/casino-game-account`}
        primaryText="Настройки игр"
        leftIcon={<accounts.icon />}
      />

      {/* @ts-ignore */}
      <MenuItemLink
        to={`/casino-game-round`}
        primaryText="Раунды"
        leftIcon={<accounts.icon />}
      />

      {/* @ts-ignore */}
      <MenuItemLink
        to={`/account-api`}
        primaryText="API"
        leftIcon={<accounts.icon />}
      />
      {/* @ts-ignore */}
      <MenuItemLink
        to={`/account`}
        primaryText="Партнеры"
        leftIcon={<accounts.icon />}
      />
      {/* @ts-ignore */}
      <MenuItemLink
        to={`/admin`}
        primaryText="Админы"
        leftIcon={<accounts.icon />}
      />

    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
  },
}));

export default Menu;