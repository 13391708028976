import * as React from 'react';
import {FC} from 'react';
import {
  Filter,
  List,
  TextInput,
  ReferenceArrayInput,
  AutocompleteInput,
  ReferenceInput,
  ListProps,
  AutocompleteArrayInput,
  BooleanField,
  ArrayField,
  Datagrid,
  ReferenceField,
  ChipField,
  SingleFieldList,
  SelectInput,
  TextField,
  SelectField,
  BooleanInput, DateField, SimpleForm
} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {csvExporter, formatCsvDate, formatListValue} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import {AdminRole} from 'src/types'
import PercentField from 'src/components/fields/PercentField'

const exporter = posts => {
  return csvExporter({
    columns: {
      'id': 'ID',
      'createdAt': 'Создан',

    },
    data:  posts.map(item => ({
      ...item,
      createdAt:  formatCsvDate(item.createdAt),
    })),
    fileName: 'CasinoGameTest'
  })
};

const _Filter = (props) => (
  <Filter {...props}>
    {(props.permissions === AdminRole.SuperAdmin || props.permissions === AdminRole.Admin)  && <ReferenceInput label="Партнер" source="accountId" reference="account" variant={'outlined'}
                    filterToQuery={searchText => ({ ...(searchText ? { 'name||$contL': searchText} : {}) })}
                    sort={{ field: 'name', order: 'ASC' }}
                    fullWidth={true} perPage={100} alwaysOn={true}>
      <AutocompleteInput optionText={(record) => `#${record.id} ${record.name}`}/>
    </ReferenceInput>}
  </Filter>
)

const ChessGameAccountSettingsList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={exporter}
      bulkActionButtons={false}
      filters={<_Filter/>}
      title="Настройки шахмат"
      sort={{field: 'id', order: 'ASC'}}
      empty={<EmptyList title={'Нет настроек шахмат'}/>}
    >
    <Datagrid>
      <IdShowButtonField source={'id'} label={'ID'}/>

      {(props.permissions === AdminRole.SuperAdmin || props.permissions === AdminRole.Admin)  && <ReferenceField label="Партнер" source="accountId" reference="account">
          <TextField source="name"  />
      </ReferenceField>}
      {(props.permissions === AdminRole.SuperAdmin || props.permissions === AdminRole.Admin)  &&  <PercentField source={'serviceCommission'} label={'Коммисия GR'}/>}
      <PercentField source={'commissionWin'} label={'Коммисия Выигрыш'}/>
      <PercentField source={'commissionDraw'} label={'Комиссия Ничья'}/>
      <DateField source="updatedAt" label={'Обновлено'} showTime={true} />
    </Datagrid>
    </List>
  );
}

export default ChessGameAccountSettingsList;
