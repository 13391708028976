import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {FileInput, TextInput, SimpleForm, required, useDataProvider, TitleForRecord, Loading} from 'react-admin';
import {useNotify} from 'ra-core'
import { Card } from '@material-ui/core';
import {httpClient, request} from 'src/common/providers/dataProvider'
export const AccountApi = ({ staticContext, ...props }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [saving, setSaving] = useState(false);
  const [account, setAccount] = useState(null);
  useEffect(() => {
    request('/account/current').then(res => setAccount(res.json))
  }, [])
console.log("Account32323", account);
  if(!account){
    return <Loading loadingPrimary="app.page.loading" loadingSecondary="app.message.loading" />
  }
  return (<div>
      <TitleForRecord
        title={'Test'}
        record={{}}
        defaultTitle={''}
      />
      <Card>
      <SimpleForm record={account}>
        <TextInput source="apiToken" validate={required()} variant={'outlined'}/>
      </SimpleForm>
      </Card>
    </div>
  );
};