import * as React from 'react';
import { useSelector } from 'react-redux';
import { Layout, LayoutProps, Sidebar } from 'react-admin';
import Menu from './Menu';

const CustomSidebar = (props: any) => <Sidebar {...props}  />;

export default (props: LayoutProps) => {

  return (
    <Layout
      {...props}
      sidebar={CustomSidebar}
    />
  );
};