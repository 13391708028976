import * as React from 'react';
import {
  ArrayInput,
  BooleanInput, FormTab, NumberInput,
  SimpleForm, SimpleFormIterator, TabbedForm, TextInput
} from 'react-admin';

const InfoTab = props => {
  return (<FormTab
    label="Шахматы"
    path={'benefit'}
    {...props}>
    <NumberInput source="chessCommissionWin" label={'Коммисия выигрыша'} variant={'outlined'} fullWidth={true}/>
    <NumberInput source="chessCommissionDraw" label={'Коммисия ничьи'}  variant={'outlined'} fullWidth={true}/>
    <NumberInput source="chessOpponentConfirmTimeout" label={'Таймаут подтвреждения опонента'}  variant={'outlined'} fullWidth={true}/>
    <NumberInput source="chessGameAutoFinishedTimeout" label={'Таймаут Авто финиша'}  variant={'outlined'} fullWidth={true}/>
    <ArrayInput source="chessGameTimes" label={'Время'} variant={'outlined'}  >
      <SimpleFormIterator>
        <TextInput source="lifetime" label={'LifeTime'} variant={'outlined'} helperText={'Время хода'} fullWidth={true}/>
        <TextInput source="increaseTime" label={'IncreaseTime'} helperText={'Добавление времени на кадом ходе'} variant={'outlined'} fullWidth={true}/>
      </SimpleFormIterator>
    </ArrayInput>
  </FormTab>)
}
export const SettingsForm = props => {
  return (
    <TabbedForm {...props} redirect={'list'}>
      <InfoTab/>
    </TabbedForm>
  );
};
