import * as React from 'react';
import {FC} from 'react';
import {
  Filter,
  List,
  TextInput,
  ReferenceArrayInput,
  AutocompleteInput,
  ReferenceInput,
  ListProps,
  AutocompleteArrayInput,
  BooleanField,
  ArrayField,
  FunctionField,
  Datagrid,
  ReferenceField,
  ChipField,
  SingleFieldList,
  SelectInput,
  TextField,
  SelectField,
  BooleanInput, DateField, SimpleForm
} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {csvExporter, formatCsvDate, formatListValue} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'

import ProfitField from 'src/components/fields/ProfitField'
import VisibilityField from 'src/components/fields/VisibilityField'
import {AdminRole, CasinoGameRoundStatusList, CasinoGameTypeList} from 'src/types'
import {convertFiat} from 'src/utils/formatters'
import DateRangeInput from "src/components/inputs/DateRangeInput";

const exporter = posts => {
  return csvExporter({
    columns: {
      'createdDate': 'Дата',
      'count': 'Кол-во',
      'wagerUsd': 'Сумма ставок',
      'profitUsd': 'Выигрыш',
      'loseUsd': 'Выигрыш',
      'ggr': 'GGR',
      'rtp': 'RTP',

    },
    data:  posts.map(item => ({
      ...item,
      ggr: item.wagerUsd - item.profitUsd,
      rtp:`${(item.profitUsd / item.wagerUsd * 100).toFixed(2)}%`,
      type: CasinoGameTypeList.find(i => i.id === item.game?.type),
      status: CasinoGameRoundStatusList.find(i => i.id === item.status),
      createdDate: formatCsvDate(item.createdDate, true)
    })),
    fileName: 'CasinoGameRoundReport'
  })
};

const _Filter = (props) => (
    <Filter {...props}>
      <SelectInput source="gameType" label={'Игра'} choices={CasinoGameTypeList} variant={'outlined'} alwaysOn={true}/>
      <DateRangeInput source={'createdAt'} sourceStart={'createdAt||$gte'} sourceEnd={'createdAt||$lte'} label="Даты"  alwaysOn={true} />
      <BooleanInput source="isDemo" label={'Демо'} variant={'outlined'} resettable={true}  alwaysOn={true}/>

    </Filter>
)

const CasinoGameRoundList: FC<ListProps> = props => {
  return (
      <List
          {...props}
          exporter={exporter}
          bulkActionButtons={false}
          title="Ставки Отчет"
          filters={<_Filter/>}
          filterDefaultValues={{ isDemo: false }}
          sort={{field: 'createdDate', order: 'DESC'}}
          empty={<EmptyList title={'Нет ставок'} description={'Вы можете добавить ставку'} buttonText={'Добавить ставку'}/>}
      >
        <Datagrid>
          <DateField source="createdDate" label={'Время'} showTime={false}/>
          <TextField source="count" label={'Кол-во'}/>
          <ProfitField source="wagerUsd" label={'Сумма ставок'} currency={'USD'} type={'bet'}/>
          <ProfitField source="profitUsd" label={'Выигрыш'} value={i => i.profitUsd} currency={'USD'}/>
          <ProfitField  label={'GGR'} value={(i) => i.wagerUsd - i.profitUsd} currency={'USD'} type={'bet'}/>
          <FunctionField  label={'RTP'} render={(i) => `${(i.profitUsd / i.wagerUsd * 100).toFixed(2)}%`}/>
        </Datagrid>
      </List>
  );
}

export default CasinoGameRoundList;
