import * as React from 'react';
import {FC, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {
  BooleanInput,
  Datagrid,
  FunctionField,
  Create,
  Pagination,
  ReferenceField,
  SimpleForm,
  DateField,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  SelectInput,
  useDataProvider,
  DeleteButton,
  useRefresh,
  TabbedForm,
  TextField,
  ReferenceManyField,
  TextInput, SelectField,
} from 'react-admin';
import RelatedList from 'src/components/list/RelatedList'
import {ModalCreateButton, ModalEditButton} from 'src/components/Modal/ModalButton'
import {CasinoGameTestTypeForm} from 'src/resources/casino-game-test/CasinoGameTestTypeForm'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {CasinoGameTestStatus, CasinoGameTestStatusList} from 'src/types'
import ProfitField from 'src/components/fields/ProfitField'
import {convertFiat} from 'src/utils/formatters'

interface TitleProps {
  record?: any;
}
const MultiplierStats = (props) => {
    const multiplierStats = props.record.multiplierStats
    const keys = Object.keys(props.record.multiplierStats)
    return <div>
        <div>Статистика коэфеценитов (коэфециент: кол-во ставок)</div>
        {keys.map(key => <div>{key}: {multiplierStats[key]}</div>)}
    </div>
}
const AviatorMultiplierStats = (props) => {
    const multiplierStats = props.record.data?.aviatorMultiplierStats ?? {}
    const keys = Object.keys(props.record.data?.aviatorMultiplierStats)
    return <div>
        <div>Статистика финальных коэфеценитов (коэфециент: кол-во раундов)</div>
        {keys.map(key => <div>{key}: {multiplierStats[key]}</div>)}
    </div>
}
const AviatorFinishStats = (props) => {
    const multiplierStats = props.record.data?.aviatorFinishMultiplierStats ?? {}
    const keys = Object.keys(props.record.data?.aviatorFinishMultiplierStats)
    return <div>
        <div>Статистика на каком коэфециенте забирают (коэфециент: кол-во ставок)</div>
        {keys.map(key => <div>{key}: {multiplierStats[key]}</div>)}
    </div>
}
const Details = (props) => {
  const record = props.record

  return (
    <div>
      <div>Среднее кол-во ходов: {((record.totalTurns / record.totalBetsFinished)).toFixed(1)}</div>

      <div>Всего принудительных проигрышей (totalFakeLose): {record.totalFakeLose}</div>
      <div>Всего принудительных проигрышей успешных подборов (totalFakeLoseCompleted): {record.totalFakeLoseCompleted}</div>
      <div>Всего принудительных проигрышей НЕуспешных подборов (totalFakeLoseMissed): {record.totalFakeLoseMissed}</div>
      <div>Всего итерация подбора serverSeed (totalFakeLoseTries): {record.totalFakeLoseTries}</div>
      <div>Всего итераций подбора serverSeed успешно (totalFakeLoseTriesSuccess): {record.totalFakeLoseTriesSuccess}</div>
      <div>Всего итераций подбора serverSeed НЕуспешно (totalFakeLoseTriesFailed): {record.totalFakeLoseTriesFailed}</div>
      <br/>
      <div>Процент успешного подбора: {((record.totalFakeLoseCompleted / record.totalFakeLose) * 100).toFixed(1)}%</div>
      <div>Процент НЕуспешного подбора: {((record.totalFakeLoseMissed / record.totalFakeLose) * 100).toFixed(1)}%</div>
      <div>Среднее Количество итераций подбора для НЕуспешных подборов: {(record.totalFakeLoseTriesFailed / record.totalFakeLoseMissed).toFixed(1)}</div>
      <div>Среднее Количество итераций подбора для успешных подборов: {(record.totalFakeLoseTriesSuccess / record.totalFakeLoseCompleted).toFixed(1)}</div>
      <div>Среднее Количество итераций подбора для всех подборов: {(record.totalFakeLoseTries / record.totalFakeLose).toFixed(1)}</div>
        <MultiplierStats record={props.record}/>
        <br/>
        <div>Авиатор</div>
        <AviatorMultiplierStats record={props.record}/>
        <AviatorFinishStats record={props.record}/>
    </div>
  );
};

export const CasinoGameTestTypeList = (props) => {
  const {record} = props;
  const inProgress = [CasinoGameTestStatus.InProgress, CasinoGameTestStatus.InQueue].includes(record.status)
  return (
    <ReferenceManyField {...props} reference="casino-game-type-test" target="gameTestId" addLabel={false}
                        sort={[{field: 'enabled', order: 'DESC'}, {field: 'game.name', order: 'ASC'}]} pagination={<Pagination/>} perPage={50}>
      <RelatedList hasCustomCreate={false} resource="casino-game-type-test" record={record} refresh={inProgress ? 10 : null}
                   emptyTitle={'Нет игр'} expand={<Details/>}>

        <FunctionField source={'game.name'} label={'Игра'} render={i => `${i.game?.name ?? i.game?.type}`}/>
        <FunctionField source={'game.name'} label={'Вкл'} render={i => i.enabled ? 'Вкл' : 'Выкл'}/>

        <SelectField source={'status'} choices={CasinoGameTestStatusList} label={'Статус'}/>
        <FunctionField label={'Прогресс'} render={i => {
          if([CasinoGameTestStatus.InProgress, CasinoGameTestStatus.Cancelled, CasinoGameTestStatus.Finished].includes(i.status)){
           if(!i.totalBetsFinished && !i.totalBets){
             return '0%'
           }
            return i.game?.type === 'aviator' ?  `${Math.floor((i.data?.totalRounds / record.aviator?.totalRounds) * 100)}%` :  `${Math.floor((i.totalBetsFinished / i.totalBets) * 100)}%`
          }else{
            return ''
          }
        }}/>
        <ProfitField source={'totalBetAmount'} label={'Ставок'} type={'bet'}/>
        <ProfitField source={'totalProfitAmount'} label={'Выиграно'} type={'win'} />
        <ProfitField source={'totalLoseAmount'} label={'Проиграно'} type={'lose'}/>
        <TextField source={'totalWin'} label={'Выигрыши'} />
        <TextField source={'totalLose'} label={'Проигрыши'} />
        <ProfitField  label={'GGR'} value={(i) => i.totalBetAmount - i.totalProfitAmount} currency={'USD'} type={'bet'}/>
        <FunctionField  label={'RTP'} render={(i) =>  i.totalBetAmount ? `${convertFiat(i.totalProfitAmount / i.totalBetAmount * 100)}%` : ''}/>
        <FunctionField source={'totalFakeLose'} label={'Принудительно'} render={i => `${((i.totalFakeLoseCompleted / i.totalFakeLose) * 100).toFixed(1)}%`} />

        <DateField source={'startedAt'} label={'Старт'} showTime={true}/>
        <DateField source={'finishedAt'} label={'Финиш'} showTime={true}/>
        <ModalEditButton label={''} modal={<CasinoGameTestTypeForm />}/>
          <TextField source={'id'} label={'ID'}/>
      </RelatedList>
    </ReferenceManyField>)
}