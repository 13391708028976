import * as React from 'react';
import {FC} from 'react';
import {
  Filter,
  List,
  TextInput,
  ReferenceArrayInput,
  AutocompleteInput,
  ReferenceInput,
  ListProps,
  FunctionField,
  AutocompleteArrayInput,
  BooleanField,
  ArrayField,
  Datagrid,
  ReferenceField,
  ChipField,
  SingleFieldList,
  SelectInput,
  TextField,
  SelectField,
  BooleanInput, DateField, SimpleForm, required
} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {csvExporter, formatCsvDate, formatListValue} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import VisibilityField from 'src/components/fields/VisibilityField'
import {
  AdminRole, AdminRoleList, CasinoGameRoundStatus,
  CasinoGameTypeList,
  PartnerApiErrorTypeList,
  TransactionStatusList,
  TransactionTypeList
} from 'src/types'
import AssetField from 'src/components/fields/AssetField'
import DateRangeInput from "src/components/inputs/DateRangeInput";

const exporter = posts => {
  return csvExporter({
    columns: {
      'id': 'ID',
      'user': 'Пользователь',
      'type': 'Тип',
      'status': 'Статус',
      'gameType': 'Игра',
      'amount': 'Сумма',
      'currency': 'Валюта',
      'amountUsd': 'Суммма USD',
      'createdAt': 'Создан',
      'finishedAt': 'Выполнена',
    },
    data:  posts.map(item => ({
      ...item,
      type: formatListValue(item.type, TransactionTypeList),
      status: formatListValue(item.status, TransactionStatusList),
      gameType: formatListValue(item.gameType, CasinoGameTypeList),
      createdAt:  formatCsvDate(item.createdAt),
      finishedAt:  formatCsvDate(item.finishedAt),
    })),
    fileName: 'CasinoGame'
  })
};

const _Filter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'} alwaysOn={true} />
    {(props.permissions === AdminRole.SuperAdmin || props.permissions === AdminRole.Admin)  &&
    <ReferenceInput label="Партнер" source="accountId" reference="account" variant={'outlined'}
                    filterToQuery={searchText => ({ ...(searchText ? { 'name||$contL': searchText} : {}) })}
                    sort={{ field: 'name', order: 'ASC' }}
                    fullWidth={true} perPage={100}>
        <AutocompleteInput optionText={(record) => `#${record.id} ${record.name}`}/>
    </ReferenceInput>}
    <DateRangeInput source={'createdAt'} sourceStart={'createdAt||$gte'} sourceEnd={'createdAt||$lte'} label="Даты"  alwaysOn={true} />

    <TextInput source="user.partnerId||$contL" label={'Поиск'} variant={'outlined'}  resettable={true}/>
    <SelectInput source="type" label={'Тип'} choices={TransactionTypeList} variant={'outlined'} />
    <SelectInput source="status" label={'Статус'} choices={TransactionStatusList} variant={'outlined'}/>
    <SelectInput source="gameType" label={'Игра'} choices={CasinoGameTypeList} variant={'outlined'}/>
    <SelectInput source="apiErrorType" label={'Статус'} choices={PartnerApiErrorTypeList} variant={'outlined'}/>
  </Filter>
)

const UserTransactionList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={exporter}
      bulkActionButtons={false}
      filters={<_Filter/>}
      title="Транзакции"
      sort={{field: 'createdAt', order: 'DESC'}}
      empty={<EmptyList title={'Нет тразнакций'} />}
    >
    <Datagrid>
      <TextField source={'id'} label={'ID'}/>
      {(props.permissions === AdminRole.SuperAdmin || props.permissions === AdminRole.Admin)  && <ReferenceField label="Партнер" source="accountId" reference="account">
          <TextField source="name"  />
      </ReferenceField>}
      <ReferenceField label="Пользователь" source="userId" reference="user">
        <FunctionField source="name"  render={i => `#${i.partnerUserId} ${i.login}`}/>
      </ReferenceField>
      <SelectField source="type" label={'Тип'} choices={TransactionTypeList}/>
      <SelectField source="status" label={'Статус'} choices={TransactionTypeList}/>
      <SelectField source="gameType" label={'Игра'} choices={CasinoGameTypeList}/>
      <FunctionField source="amount" label={'Сумма'} render={i => `${Math.abs(i.amount)} ${i.currency} ${i.amountUsdT ? `(${Math.abs(i.amountUsdT)} USD)` : ''}`}/>
      <DateField source="createdAt" label={'Создана'} showTime={true} />
      <DateField source="finishedAt" label={'Выполнена'} showTime={true} />
      <SelectField source="apiErrorType" label={'API Ошибка'} choices={PartnerApiErrorTypeList}/>
    </Datagrid>
    </List>
  );
}

export default UserTransactionList;
