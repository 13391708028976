import * as React from 'react';

import {
  BooleanInput,
  Datagrid,
  ReferenceInput,
  TabbedForm,
  ArrayInput,
  SimpleFormIterator,
  FormTab,
  required,
  SelectInput,
  TextInput, FormDataConsumer, Filter, NumberInput, SimpleForm,
} from 'react-admin';
import {AdminRole} from 'src/types'

const InfoTab = props => {
  return (<FormTab
    label="Информация"
    path={'benefit'}
    {...props}>
    {(props.permissions === AdminRole.SuperAdmin || props.permissions === AdminRole.Admin)  &&     <NumberInput source="serviceCommission" label={'Коммисия GR'} variant={'outlined'} fullWidth={true}/>}
    <NumberInput source="commissionWin" label={'Коммисия выигрыша'} variant={'outlined'} fullWidth={true}/>
    <NumberInput source="commissionDraw" label={'Коммисия ничьи'}  variant={'outlined'} fullWidth={true}/>
    <NumberInput source="opponentConfirmTimeout" label={'Таймаут подтвреждения опонента'}  variant={'outlined'} fullWidth={true}/>
    <NumberInput source="gameAutoFinishedTimeout" label={'Таймаут Авто финиша'}  variant={'outlined'} fullWidth={true}/>
    <ArrayInput source="gameTimes" label={'Время'} variant={'outlined'}  >
      <SimpleFormIterator>
        <NumberInput source="lifetime" label={'LifeTime'} variant={'outlined'} helperText={'Время хода'} fullWidth={true}/>
        <NumberInput source="increaseTime" label={'IncreaseTime'} helperText={'Добавление времени на кадом ходе'} variant={'outlined'} fullWidth={true}/>
      </SimpleFormIterator>
    </ArrayInput>
  </FormTab>)
}

export const ChessGameAccountSettingsForm = props => {
  return (
    <TabbedForm {...props} redirect={'list'}>
      <InfoTab {...props}/>
    </TabbedForm>
  );
};
