import * as React from 'react';
import {FC} from 'react';
import {Box, Chip, useMediaQuery, Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {
  CreateButton,
  ExportButton,
  ReferenceField,
  Filter,
  SelectField,
  List,
  FilterProps,
  InputProps,
  ListBase,
  ListProps,
  Datagrid,
  TextField,
  TopToolbar,
  useListContext,
  useTranslate, DateField, SelectInput, TextInput, SimpleForm,
} from 'react-admin';
import {AdminRole, AdminRoleList} from 'src/types'
import {csvExporter, formatListValue} from 'src/utils/exporter'


const exporter = posts => {
  return csvExporter({
    columns: {
      'name': 'Имя',
      'email': 'Email',
      'role': 'Роль',
      'account': 'Партнер'

    },
    data:  posts.map(item => ({
      ...item,
      role: formatListValue(item.role, AdminRoleList),
      account: item.account?.name
    })),
    fileName: 'Accounts'
  })
};

const _Filter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'} />
    <TextInput source="name||$contL" label={'Поиск'} variant={'outlined'} alwaysOn={true} resettable={true}/>

  </Filter>
)

const AdministratorList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={exporter}
      bulkActionButtons={false}
      filters={<_Filter/>}
      title="Администраторы"
    >
    <Datagrid rowClick={'edit'}>
      <TextField source="name" label={'Имя'}/>
      <TextField source="email" label={'Email'}/>
      {(props.permissions === AdminRole.SuperAdmin || props.permissions === AdminRole.Admin)  && <ReferenceField label="Партнер" source="accountId" reference="account">
        <TextField source="name"  />
      </ReferenceField>}
      <SelectField
        source="role"
        label={'Роль'}
        choices={AdminRoleList}
      />
      <DateField source={'createdAt'} label={'Создан'}/>
    </Datagrid>
    </List>
  );
}

export default AdministratorList;
