import PersonIcon from '@material-ui/icons/Person';
import UserList from './UserList'
import UserShow from './/UserShow'
import UserCreate from 'src/resources/user/UserCreate'
import UserEdit from 'src/resources/user/UserEdit'

export default {
  edit: UserEdit,
  show: UserShow,
  list: UserList,
  icon: PersonIcon,
};