import LocationOnIcon from '@material-ui/icons/LocationOn';
import CasinoGameTestCreate from 'src/resources/casino-game-test/CasinoGameTestCreate'
import CasinoGameTestEdit from 'src/resources/casino-game-test/CasinoGameTestEdit'
import CasinoGameTestList from 'src/resources/casino-game-test/CasinoGameTestList'

export default {
  create: CasinoGameTestCreate,
  edit: CasinoGameTestEdit,
  list: CasinoGameTestList,
  icon: LocationOnIcon,
};