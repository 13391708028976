import * as React from 'react';
import { FC, ReactElement } from 'react';
import { linkToRecord, Record } from 'ra-core';

import {
 FunctionField,
} from 'react-admin';
import {getTranslation} from 'src/utils/translation'
interface PercentFieldProps {
  basePath?: string;
  record?: Record;
  source?: string
  label?: string,
}
const PercentField: FC<PercentFieldProps> = (props) => {
  const {
    basePath = '',
      record,
      source,
  ...rest
  } = props;

  return <FunctionField {...props} source={source} render={(i) => `${i[source]}%`}/>
}



export default PercentField
