import * as React from 'react';
import {FC, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {
  BooleanInput,
  Datagrid,
  Create,
  SimpleForm,
  DateField,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  SelectInput,
  DeleteButton,
  TabbedForm,
  TextField,
  TextInput,
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import {AdministratorForm} from 'src/resources/administrators/AdministratorForm'

export const createStyles = {
  price: {width: '7em'},
  width: {width: '7em'},
  height: {width: '7em'},
  stock: {width: '7em'},
  widthFormGroup: {display: 'inline-block'},
  heightFormGroup: {display: 'inline-block', marginLeft: 32},
};

interface ProductTitleProps {
  record?: any;
}

const AffirmationTitle: FC<ProductTitleProps> = ({record}) =>
  <span>Новый Администратор</span>

const useStyles = makeStyles({
  ...createStyles,
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tab: {
    maxWidth: '40em',
    display: 'block',
  },
});
const AffirmationCreate: FC<EditProps> = props => {
  const classes = useStyles();
  console.log("PropsAdmin", props);
  return (
    <Create {...props}  title={<AffirmationTitle/>}>
      <AdministratorForm permissions={props.permissions}/>
    </Create>
  );
};

const requiredValidate = [required()];

export default AffirmationCreate;