import * as React from 'react';
import {FC} from 'react';
import {
  Filter,
  List,
  TextInput,
  ReferenceArrayInput,
  AutocompleteInput,
  ReferenceInput,
  ListProps,
  AutocompleteArrayInput,
  BooleanField,
  ArrayField,
  Datagrid,
  ReferenceField,
  ChipField,
  SingleFieldList,
  SelectInput,
  TextField,
  SelectField,
  BooleanInput, DateField, SimpleForm
} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {csvExporter, formatCsvDate, formatListValue} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import VisibilityField from 'src/components/fields/VisibilityField'
import {CasinoGameTypeList} from 'src/types'
import AssetField from 'src/components/fields/AssetField'

const exporter = posts => {
  return csvExporter({
    columns: {
      'published': 'Опубликовано',
      'name': 'Название',
      'slug': 'URI',

    },
    data:  posts.map(item => ({
      ...item,
    })),
    fileName: 'CasinoGame'
  })
};

const _Filter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'} />
    <TextInput source="name||$contL" label={'Поиск'} variant={'outlined'} alwaysOn={true} resettable={true}/>
    <SelectInput source="type" label={'Тип'} choices={CasinoGameTypeList} variant={'outlined'} alwaysOn={true}/>

  </Filter>
)

const CasinoGameList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={exporter}
      bulkActionButtons={false}
      filters={<_Filter/>}
      title="Игры"
      sort={{field: 'name', order: 'ASC'}}
      empty={<EmptyList title={'Нет игр'} description={'Вы можете добавить игру'} buttonText={'Добавить игру'}/>}
    >
    <Datagrid>
      <IdShowButtonField source={'id'} label={'ID'}/>
      <AssetField source={'icon'} label={'Картинка'}/>
      <AssetField source={'iconSmall'} label={'Иконка'}/>
      <TextField source="name" label={'Название'}/>
      <VisibilityField source="published" label={'П'}/>
      <SelectField source="type" label={'Тип'} choices={CasinoGameTypeList}/>
      <TextField source="name" label={'Название'}/>
    </Datagrid>
    </List>
  );
}

export default CasinoGameList;
