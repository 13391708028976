import * as React from 'react';

import {
  BooleanInput,
  Datagrid,
  ReferenceInput,
  TabbedForm,
  ArrayInput,
  SimpleFormIterator,
  FormTab,
  required,
  SelectInput,
  TextInput, FormDataConsumer, Filter,
} from 'react-admin';
import {useForm} from 'react-final-form'
import {slug} from 'src/utils/formatters'
import {RichTextInput} from 'src/components/inputs/RichTextInput'
import {CasinoGameTypeList, LanguageList} from 'src/types'
import {MediaInput} from 'src/components/inputs/MediaInput'

const InfoTab = props => {
  return (<FormTab
    label="Информация"
    path={'benefit'}
    {...props}>
    <BooleanInput source="published" label={'Опубликовано'} variant={'outlined'} defaultValue={true}/>
    <SelectInput source="type" label={'Игра'} choices={CasinoGameTypeList} variant={'outlined'} alwaysOn={true}/>
    <TextInput source={'name'} label={'Название'} variant={'outlined'} validate={required()} fullWidth/>
    <MediaInput source={'icon'} label={'Иконка'} />
    <MediaInput source={'iconSmall'} label={'Иконка маленькая'} />
    <ArrayInput source="translations" label={'Контент'}>
      <SimpleFormIterator>
        <SelectInput source="languageCode" label={'Язык'}  variant={'outlined'} choices={LanguageList} validate={required()} />
        <RichTextInput source="help" label={'Помощь'} multiline={true} fullWidth={true} variant={'outlined'} validate={required()} />
        <RichTextInput source="helpFairness" label={'Помощь Четстность'} multiline={true} fullWidth={true} variant={'outlined'}/>
      </SimpleFormIterator>
    </ArrayInput>
  </FormTab>)
}

export const CasinoGameForm = props => {
  return (
    <TabbedForm {...props} redirect={'list'}>
      <InfoTab/>
    </TabbedForm>
  );
};
