import * as React from 'react';
import {FC} from 'react';
import {
  Filter,
  List,
  TextInput,
  ReferenceArrayInput,
  AutocompleteInput,
  ReferenceInput,
  ListProps,
  FunctionField,
  AutocompleteArrayInput,
  BooleanField,
  ArrayField,
  Datagrid,
  ReferenceField,
  ChipField,
  SingleFieldList,
  SelectInput,
  TextField,
  SelectField,
  BooleanInput, DateField, SimpleForm
} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {csvExporter, formatCsvDate, formatListValue} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import VisibilityField from 'src/components/fields/VisibilityField'
import {
  AdminRole, AdminRoleList, AviatorGameRoundStatusList, CasinoGameRoundStatus,
  CasinoGameTypeList,
  PartnerApiErrorTypeList,
  TransactionStatusList,
  TransactionTypeList
} from 'src/types'
import AssetField from 'src/components/fields/AssetField'
import DateRangeInput from "src/components/inputs/DateRangeInput";

const exporter = posts => {
  return csvExporter({
    columns: {
      'id': 'ID',
      'accountId': 'ID партнера',
      'betAmount': 'Ставка',
      'profit': 'Выигрыш',
      'currency': 'Валюта',
      'betAmountUsd': 'Ставка USD',
      'profitUsd': 'Ставка USD',
      'multiplier': 'Коэффициент',
      'aviatorGameRoundId': 'ID раунда',
      'autoStopMultiplier': 'Авто стоп',
      'isFinished': 'Завершен',
      'createdAt': 'Создан'

    },
    data:  posts.map(item => ({
      ...item,
      accountName: item.account?.name ?? '',
      accountId: item.accountId ?? '',
      userId: item.user?.partnerUserId ?? '',
      isFinished: item.isFinished ? 'Завершен' : '',
      createdAt:  formatCsvDate(item.createdAt),

    })),
    fileName: 'AviatorGameRoundBet'
  })
};

const _Filter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'} />
    <DateRangeInput source={'createdAt'} sourceStart={'createdAt||$gte'} sourceEnd={'createdAt||$lte'} label="Даты"  alwaysOn={true} />

    {(props.permissions === AdminRole.SuperAdmin || props.permissions === AdminRole.Admin)  &&
    <ReferenceInput label="Партнер" source="accountId" reference="account" variant={'outlined'}
                    filterToQuery={searchText => ({ ...(searchText ? { 'name||$contL': searchText} : {}) })}
                    sort={{ field: 'name', order: 'ASC' }}
                    fullWidth={true} perPage={100}>
        <AutocompleteInput optionText={(record) => `#${record.id} ${record.name}`}/>
    </ReferenceInput>}
    <TextField source={'user.partnerUserId'} label={'ID игрока'}/>
    {(props.permissions === AdminRole.SuperAdmin || props.permissions === AdminRole.Admin)  &&
    <ReferenceInput label="Пользователь" source="userId" reference="user" variant={'outlined'}
                    filterToQuery={searchText => ({ ...(searchText ? { 'partnerUserId||$contL': searchText} : {}) })}
                    sort={{ field: 'partnerUserId', order: 'ASC' }}
                    fullWidth={true} perPage={100}>
        <AutocompleteInput optionText={(record) => `#${record.id} ${record.name}`}/>
    </ReferenceInput>}

  </Filter>
)

const AviatorGameRoundBetList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={exporter}
      bulkActionButtons={false}
      filters={<_Filter/>}
      title="Авиатор Ставки"
      sort={{field: 'createdAt', order: 'DESC'}}
      empty={<EmptyList title={'Нет ставок'} />}
    >
    <Datagrid>
      <TextField source={'id'} label={'ID'}/>
      {(props.permissions === AdminRole.SuperAdmin || props.permissions === AdminRole.Admin)  && <ReferenceField label="Партнер" source="accountId" reference="account">
          <TextField source="name"  />
      </ReferenceField>}
      <ReferenceField label="Пользователь" source="userId" reference="user">
        <FunctionField source="name"  render={i => `#${i.partnerUserId} ${i.login}`}/>
      </ReferenceField>

      <FunctionField source="betAmount" label={'Ставка'} render={i => `${Math.abs(i.betAmount)} ${i.currency} ${i.betAmountUsd ? `(${Math.abs(i.betAmountUsd)} USD)` : ''}`}/>
      <FunctionField source="profit" label={'Выигрыш'} render={i => `${Math.abs(i.profit)} ${i.currency} ${i.profitUsd ? `(${Math.abs(i.profitUsd)} USD)` : ''}`}/>
      <TextField source={'aviatorGameRoundId'} label={'ID раунда'}/>
      <TextField source={'multiplier'} label={'Коэффициент'}/>
      <TextField source={'autoStopMultiplier'} label={'Автостоп'}/>
      <FunctionField source={'isFinished'} label={'Статус'} render={(i) => i.isFinished ? 'Завершен' : '-'}/>
      <DateField source="createdAt" label={'Создана'} showTime={true} />
    </Datagrid>
    </List>
  );
}

export default AviatorGameRoundBetList;
