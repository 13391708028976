import slugify from 'slugify'
const PNF = require('google-libphonenumber').PhoneNumberFormat;
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

export const slug = (val) => val ? slugify(val)?.replace(/[`~!@#$%^&*()|+=?;:'",.<>\{\}\[\]\\\/]/gi, '')?.toLowerCase()?.trim() || '' : '';
export const formatPhone = (phone) => {
  try {
    const number = phoneUtil.parseAndKeepRawInput(phone, 'RU');
    return phoneUtil.format(number, PNF.INTERNATIONAL)
  }catch (e){
    console.error(e);
    return phone;
  }
}
const pluralizeNative = require('numeralize-ru').pluralize
export const pluralize = (number, word1, word2, word3) => {
  return pluralizeNative(number, word1, word2, word3)
}
export const isString = thing => Object.prototype.toString.call(thing) === '[object String]';

export const formatDataWithTranslation = ({fields, data, langCode}) => {
  const translations = data.translations || [];
  let currentTranslationIndex = translations.findIndex(i => i.languageCode === langCode);
  if(currentTranslationIndex === -1) {
    currentTranslationIndex = 0;
    translations.push({languageCode: langCode});
  }
  const newData = {...data};
  for (const i of fields) {
    translations[currentTranslationIndex][i] = newData[i] || '';

    delete newData[i];
  }
  return {...newData, translations};
}
export const formatNumber = (num) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}
export const formatPrice = (price: number) => {
  if(!price){
    return;
  }
  return `${formatNumber(Math.ceil(price ))} ₽`
}

export const formatBonuses = (bonuses: number, withStr = false) => {

  const number = bonuses ? Math.ceil(bonuses / 100) : 0;
  return `${formatNumber(number)}${withStr ? ` Б` : ''}`
}

export const convertJson = (json) => {
  if(!json){
    return;
  }
  try{

    return JSON.parse(json);
  }catch (e){
    return null;
  }
}
export const formatUserName = (record: any) => {
  return  `${record?.phone} ${ (record?.firstName || record?.lastName)  ? `(${formatUserFirstLastName(record)})` : ''}`
}
export const formatUserFirstLastName = (record: any) => {
  return  `${ (record?.firstName || record?.lastName) ? ` ${record?.lastName || ''}${record?.firstName ? ` ${record?.firstName }` : ''}` : ''}`
}
export const convertFiat = (amount) => {
  if(amount < 0.0000001){
    return amount.toFixed(8)
  }else if(amount < 0.000001){
    return amount.toFixed(7)
  }else if(amount < 0.00001){
    return amount.toFixed(6)
  }else if(amount < 0.0001){
    return amount.toFixed(5)
  }else if(amount < 0.001){
    return amount.toFixed(4)
  }else if(amount < 0.01){
    return amount.toFixed(3)
  }else{
    return amount?.toFixed(2)
  }
}
export const formatAmount = (amount: number, currency: string) => {
  const convertFiat = (amount) => {
    if(!amount){
      return '0'
    }
    if(Math.abs(amount) < 0.0000001){
      return amount.toFixed(8)
    }else if(Math.abs(amount) < 0.000001){
      return amount.toFixed(7)
    }else if(Math.abs(amount) < 0.00001){
      return amount.toFixed(6)
    }else if(Math.abs(amount) < 0.0001){
      return amount.toFixed(5)
    }else if(Math.abs(amount) < 0.001){
      return amount.toFixed(4)
    }else if(Math.abs(amount) < 0.01){
      return amount.toFixed(3)
    }else{
      return amount?.toFixed(2)
    }
  }
  const isCrypto = ['ADA',
    'BCH',
    'BNB',
    'BSV',
    'BTC',
    'XBT',
    'DASH',
    'DOGE',
    'EOS',
    'ETH',
    'LTC',
    'VTC',
    'XLM',
    'XMR',
    'XNO',
    'XRP',
    'XTZ',
    'ZEC'].includes(currency.toUpperCase())
  return isCrypto ? amount?.toFixed(8)?.replace(/(\.[0-9]*[1-9])0+$|\.0*$/,'$1') : convertFiat(amount)?.replace(/(\.[0-9]*[1-9])0+$|\.0*$/,'$1')
}