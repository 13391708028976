import * as React from 'react';
import {FC} from 'react';
import {
  Filter,
  List,
  TextInput,
  ReferenceArrayInput,
  AutocompleteInput,
  ReferenceInput,
  ListProps,
  FunctionField,
  AutocompleteArrayInput,
  BooleanField,
  ArrayField,
  Datagrid,
  ReferenceField,
  ChipField,
  SingleFieldList,
  SelectInput,
  TextField,
  SelectField,
  BooleanInput, DateField, SimpleForm
} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {csvExporter, formatCsvDate, formatListValue} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import VisibilityField from 'src/components/fields/VisibilityField'
import {
  AdminRole, AdminRoleList, AviatorGameRoundStatusList, CasinoGameRoundStatus,
  CasinoGameTypeList,
  PartnerApiErrorTypeList,
  TransactionStatusList,
  TransactionTypeList
} from 'src/types'
import AssetField from 'src/components/fields/AssetField'
import DateRangeInput from "src/components/inputs/DateRangeInput";

const exporter = posts => {
  return csvExporter({
    columns: {
      'id': 'ID',
      'status': 'Статус',
      'multiplier': 'Коэффициент',
      'totalPlayers': 'Кол-во игрок',
      'createdAt': 'Создан',
      'plannedStartAt': 'Планируемый старт',
      'startedAt': 'Старт факт',
      'finishedAt': 'Завершен',

    },
    data:  posts.map(item => ({
      ...item,
      accountName: item.account?.name ?? '',
      accountId: item.accountId ?? '',
      status: formatListValue(item.status, AviatorGameRoundStatusList),
      createdAt:  formatCsvDate(item.createdAt),
      plannedStartAt:  formatCsvDate(item.plannedStartAt),
      startedAt:  formatCsvDate(item.startedAt),
      finishedAt:  formatCsvDate(item.finishedAt),
    })),
    fileName: 'CasinoGame'
  })
};

const _Filter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'} />
    {(props.permissions === AdminRole.SuperAdmin || props.permissions === AdminRole.Admin)  &&
    <ReferenceInput label="Партнер" source="accountId" reference="account" variant={'outlined'}
                    filterToQuery={searchText => ({ ...(searchText ? { 'name||$contL': searchText} : {}) })}
                    sort={{ field: 'name', order: 'ASC' }}
                    fullWidth={true} perPage={100}>
        <AutocompleteInput optionText={(record) => `#${record.id} ${record.name}`}/>
    </ReferenceInput>}
    <SelectInput source="status" label={'Статус'} choices={AviatorGameRoundStatusList} variant={'outlined'}/>
    <DateRangeInput source={'createdAt'} sourceStart={'createdAt||$gte'} sourceEnd={'createdAt||$lte'} label="Даты"  alwaysOn={true} />

  </Filter>
)

const AviatorGameRoundList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={exporter}
      bulkActionButtons={false}
      filters={<_Filter/>}
      title="Авиатор раунды"
      sort={{field: 'createdAt', order: 'DESC'}}
      empty={<EmptyList title={'Нет раундов'} />}
    >
    <Datagrid>
      <TextField source={'id'} label={'ID'}/>
      {(props.permissions === AdminRole.SuperAdmin || props.permissions === AdminRole.Admin)  && <ReferenceField label="Партнер" source="accountId" reference="account">
          <TextField source="name"  />
      </ReferenceField>}
      <SelectField source="status" label={'Статус'} choices={AviatorGameRoundStatusList}/>
      <TextField source={'multiplier'} label={'Коэффициент'}/>
      <TextField source={'totalPlayers'} label={'Кол-во игроков'}/>
      <DateField source="createdAt" label={'Создан'} showTime={true} />
      <DateField source="plannedStartAt" label={'Планируемый старт'} showTime={true} />
      <DateField source="startedAt" label={'Старт факт'} showTime={true} />
      <DateField source="finishedAt" label={'Завершен'} showTime={true} />
    </Datagrid>
    </List>
  );
}

export default AviatorGameRoundList;
