import * as React from 'react';

import {
  BooleanInput,
  Datagrid,
  ReferenceInput,
  TabbedForm,
  FormTab,
  required,
  SelectInput,
  TextInput, FormDataConsumer,
} from 'react-admin';
import {useForm} from 'react-final-form'
import {slug} from 'src/utils/formatters'

const InfoTab = props => {
  const form = useForm();

  return (<FormTab
    label="Информация"
    path={'benefit'}
    {...props}>
    <TextInput source={'name'} label={'Название'}  variant={'outlined'} validate={required()} fullWidth/>
    <TextInput source={'webHookUrl'} label={'ВебХук'}  variant={'outlined'} fullWidth/>
    <TextInput source={'webHookUrl'} label={'ВебХук'}  variant={'outlined'} fullWidth/>
  </FormTab>)
}

export const AccountForm = props => {
  return (
    <TabbedForm {...props} redirect={'list'}>
      <InfoTab/>
    </TabbedForm>
  );
};
