import * as React from 'react';
import {FC, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {
  BooleanInput,
  Datagrid,
  DateField,
  SimpleForm,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  SelectInput,
  DeleteButton,
  TabbedForm,
  TextField,
  TextInput,
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import {CasinoGameForm} from 'src/resources/casino-game/CasinoGameForm'
import {getTranslation} from 'src/utils/translation'

interface TitleProps {
  record?: any;
}
const Title: FC<TitleProps> = ({record}) =>
  record ? <span>Игра {record.name}</span> : null;

const CasinoGameEdit: FC<EditProps> = props => {
  return (
    <Edit {...props} mutationMode="pessimistic" title={<Title/>}>
     <CasinoGameForm/>
    </Edit>
  );
};
export default CasinoGameEdit;