import * as React from 'react';

import {
  BooleanInput,
  Datagrid,
  ReferenceInput,
  TabbedForm,
  FormTab,
  required,
  SelectInput,
  TextInput, FormDataConsumer,
} from 'react-admin';
import {useForm} from 'react-final-form'
import {slug} from 'src/utils/formatters'

const InfoTab = props => {
  const form = useForm();
  const handleNameChange = (e) => {
    if (props.record?.id) {
      return;
    }
    form.change('slug', slug(e.currentTarget.value));
  }
  return (<FormTab
    label="Информация"
    path={'benefit'}
    {...props}>
    <BooleanInput source="published" label={'Опубликовано'} variant={'outlined'} defaultValue={true}/>
    <TextInput source={'name'} label={'Название'} onChange={handleNameChange}  variant={'outlined'} validate={required()} fullWidth/>
    <TextInput source={'slug'} label={'URI'} variant={'outlined'} validate={required()} fullWidth/>
  </FormTab>)
}

export const CasinoGameRoundForm = props => {
  return (
    <TabbedForm {...props} redirect={'list'}>
      <InfoTab/>
    </TabbedForm>
  );
};
