import { makeStyles } from "@material-ui/core/styles";
import MuiTextField from "@material-ui/core/TextField/TextField";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useInput, FieldTitle } from 'ra-core';
import { DatePicker, TimePicker, DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {format as dateFormat} from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {TextInput, useTranslate} from "react-admin";
import sanitizeRestProps from "src/components/sanitizeRestProps";
import { InputHelperText, Labeled } from 'ra-ui-materialui'

const useStyles = makeStyles(
    theme => ({
      wrapper: {
        position: 'absolute',
        zIndex: 3,
      },
      input: {

      }
    }),
    { },
)
const DateRangeInput = (props) => {
  const {...fieldProps } = props
  const classes = useStyles(props)
  const {
    options,
    label,
    source,
    sourceStart,
    sourceEnd,
    format,
    parse,
    resource,
    className,
    providerOptions,
    validate,
    ...rest
  } = fieldProps;
  const [open, setOpen] = React.useState(false);
  const translate = useTranslate()
  const toggle = () => {
    if(  isResetRef.current ){
      isResetRef.current = false;
      return;
    }
    setOpen(!open);
  }
  const isResetRef = useRef<boolean>( false)
  const {
    id,
    input: { onChange:  onChangeInput, value, ...inputProps },
    isRequired,
    meta: { error, touched },
  } = useInput({
    format,

    parse,
    resource,
    source: sourceStart,
    type: 'text',
    validate,
    ...rest,
  });
  const {

    input: { onChange:  onChangeInput1, value: valueEnd },

  } = useInput({
    format,
    parse,
    resource,
    source: sourceEnd,
    type: 'text',
    validate,
    ...rest,
  });

  const handleChange = (value: DateRange) => {
    console.log("HandleChange", value.startDate, value.endDate, dateFormat(value.startDate, 'dd.MM.yy'),  dateFormat(value.endDate, 'dd.MM.yy'))
    console.log("DateProps", props)
    onChangeInput(value.startDate)
    onChangeInput1(value.endDate)
    toggle()
    //  onChangeInpu({lat: e.latLng.lat(), lng: e.latLng.lng()})
    //Date.parse(value) ? input.onChange(value.toISOString()) : input.onChange(null);
  }
  const formatDate = (date) => {
    if(!date){
      return '';
    }
    console.log("formatDate", date)
    try {
      return dateFormat(new Date(date), 'dd.MM.yy')
    }catch (e){
      return '';
    }
  }
  const formatValue = (value1) => {
    if(!value && !valueEnd){
      return '';
    }
    console.log("fsdsad", value, valueEnd)

    return `${formatDate(value)} - ${formatDate(valueEnd)}`
  }
  const getInitialDateRange = () => {
    if(!value || !valueEnd){
      return null;
    }
    return {
      startDate: value,
      endDate: valueEnd
    }
  }
  const handleReset = () => {
    onChangeInput1(null)
    onChangeInput(null)
    isResetRef.current = true;
  }
  return (
      <>

        <TextInput
            source={source}
            onChange={handleReset}
            resettable
            label={label}
            helperText={false}
            variant={'outlined'}
            onClick={toggle}
            value={formatValue}
            format={formatValue}
        />
        {open && <div className={classes.wrapper}>
          <DateRangePicker
              initialDateRange={getInitialDateRange()}
              open={open}
              wrapperClassName={classes.wrapper}
              toggle={toggle}
              onChange={(range) => handleChange(range)}
          />
        </div>}

      </>
  )
}

DateRangeInput.propTypes = {

  isRequired: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  sourceStart: PropTypes.string,
  sourceEnd: PropTypes.string,
  labelTime: PropTypes.string,
  className: PropTypes.string,
  alwaysOn: PropTypes.bool,
  providerOptions: PropTypes.shape({
    utils: PropTypes.func,
    locale: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
};

DateRangeInput.defaultProps = {
  meta: { touched: false, error: false },
  options: {},
  resource: '',
  source: '',
  labelTime: '',
  className: '',
  alwaysOn: false,
  providerOptions: {
    utils: DateFnsUtils,
    locale: undefined,
  },
};

export default DateRangeInput
