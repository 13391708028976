import * as React from 'react';
import { FC, ReactElement } from 'react';
import PropTypes from 'prop-types';
import shouldUpdate from 'recompose/shouldUpdate';
import ImageEye from '@material-ui/icons/RemoveRedEye';
import { Link } from 'react-router-dom';
import { linkToRecord, Record } from 'ra-core';
import {Button} from 'react-admin'

import { Typography } from '@material-ui/core';
import {formatAmount} from 'src/utils/formatters'
interface ProfitFieldProps {
  basePath?: string;
  record?: Record;
  source?: string
  label?: string,
  value?: (i: Record) => any | string
  currencySource?: string
  currency?: string
  type?: 'win' | 'lose' | 'bet'
}
const ProfitField: FC<ProfitFieldProps> = ({
                                           basePath = '',
  value,
                                           record,
                                           source,
  type,
                                                         ...rest
                                         }) => {

  const amount = typeof value === 'function' ? value(record) : value || record[source]
  const currency = rest.currency || record[rest.currencySource] || '$'
  const getColor = () => {
    if(!type && amount < 0){
      return 'red'
    }
    if(!type && amount > 0){
      return 'green'
    }
    switch (type){
      case 'win':
        return 'green'
      case 'lose':
        return 'red'
      case 'bet':
      default:
        return 'black'
    }
  }
  return (<Typography variant={'body2'} ><span style={{color: getColor(), whiteSpace: 'nowrap'}} >{formatAmount(amount, currency)} {currency === 'USD' ? '$' : currency}</span></Typography>

  )
}



export default ProfitField
