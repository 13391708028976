import React, {useCallback, useEffect, useRef, useState} from 'react';
import ImageCompress from 'quill-image-compress';
import MagicUrl from 'quill-magic-url'
import htmlEditButton from "quill-html-edit-button";
import RaRichTextInput from 'ra-input-rich-text';
import Quill from "quill";
import {Sources} from "quill";
import "quill-emoji/dist/quill-emoji.css";
import * as Emoji from "quill-emoji";
import 'quill-paste-smart';
import { ImageDrop } from 'quill-image-drop-module';
import ModalMedia from 'src/components/ModalMedia'
import {getMediaPath} from 'src/utils/media'
import {ModalHtmlEditor} from 'src/components/inputs/RichTextInput/HtmlForm'
import {CardEditableModule, ImageBlot} from 'src/components/inputs/RichTextInput/blots/ImageBlot'
import {ModalEditorPreview} from 'src/components/inputs/RichTextInput/ModalEditorPreview'
var icons = Quill.import('ui/icons');
Quill.register({
  // Other formats or modules
  'formats/image': ImageBlot,
  'modules/cardEditable': CardEditableModule,
}, true);
icons['html'] = '<>';
export function OutputHTMLParser(inputHtmlFromQuillPopup: string): string {
  return Compose(
    [
      ConvertMultipleSpacesToSingle,
      FixTagSpaceOpenTag,
      FixTagSpaceCloseTag,
      PreserveNewlinesBr,
      PreserveNewlinesPTags,
    ],
    inputHtmlFromQuillPopup
  );
}

export function ConvertMultipleSpacesToSingle(input: string): string {
  return input.replace(/\s+/g, " ").trim();
}

export function PreserveNewlinesBr(input: string): string {
  return input.replace(/<br([\s]*[\/]?>)/g, "<p> </p>");
}

export function PreserveNewlinesPTags(input: string): string {
  return input.replace(/<p><\/p>/g, "<p> </p>");
}

export function FixTagSpaceOpenTag(input: string): string {
  // Open tag remove space on inside
  return input.replace(/(<(?!\/)[\w=\."'\s]*>) /g, "$1");
}

export function FixTagSpaceCloseTag(input: string): string {
  // Close tag remove space on inside
  return input.replace(/ (<\/[\w]+>)/g, "$1");
}

export function Compose<T>(functions: Array<(input: T) => T>, input: T): T {
  return functions.reduce((acc, cur) => cur(acc), input);
}
Quill.register("modules/emoji", Emoji);

Quill.register('modules/magicUrl', MagicUrl)
function $create(elName: string) {
  return document.createElement(elName);
}
function $setAttr(el: HTMLElement, key: string, value: string) {
  return el.setAttribute(key, value);
}
icons["undo"] = '<img src="https://img.icons8.com/material-two-tone/24/000000/undo.png"/>';
icons["redo"] = '<img src="https://img.icons8.com/material-outlined/24/000000/redo.png"/>';

export const RichTextInput = (props) => {
  const [showMediaModal, setShowMediaModal] = useState(false);
  const [showHtmlModal, setShowHtmlModal] = useState(false);
  const [showPreviewDesktop, setShowPreviewDesktop] = useState(false);
  const [editorHtml, setEditorHtml] = useState(null);
  const quillRef = useRef(null);
  const undo = () => {
    console.log("Undo")
    return quillRef.current.history.undo();
  }

  const redo = () => {
    return quillRef.current.history.redo();
  }
  const RichTextOptions = {
    modules: {
      history: {
        delay: 2000,
        maxStack: 500,
        userOnly: false
      },
      cardEditable: true,
      magicUrl: true,
      "emoji-toolbar": true,
      "emoji-shortname": true,
      clipboard: {
        allowed: {
          tags: ['a', 'b', 'strong', 'u', 's', 'i', 'p', 'br', 'ul', 'ol', 'li', 'img', 'span'],
          attributes: ['href', 'rel', 'target', 'class']
        },
        keepSelection: true,
        substituteBlockElements: false,
        magicPasteLinks: true,
        matchVisual: false,
        hooks: {
          uponSanitizeElement(node, data, config) {
            console.log(node);
          },
        },
      },

      image: false,
      toolbar: {container : [
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          ['blockquote', 'link', 'emoji'],       // custom button values
          [{'list': 'ordered'}, {'list': 'bullet'}],
          [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
          [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
          // text direction
          [{'header': [1, 2, 3, 4, 5, 6, false]}],


          ['image', 'clean'],
          ['undo'],
          ['redo'],
        ],
        handlers: {
          'undo': undo,
          'redo': redo,
        }
      },
    }
  }
  const registerToolbarButton = ({quill, onClick, title, icon}) => {
    const toolbarModule = quill.getModule("toolbar");
    registerDivModule();
    let toolbarEl = toolbarModule.container;
    const buttonContainer = $create("span");
    $setAttr(buttonContainer, "class", "ql-formats");
    const button = $create("button") as HTMLButtonElement;
    button.innerHTML = icon;
    button.title = title;
    button.type = "button";
    button.onclick = function (e) {
      e.preventDefault();
     onClick();
    };
    buttonContainer.appendChild(button);
    toolbarEl.appendChild(buttonContainer);
  }
  const configureQuill = quill => {
    quillRef.current = quill;
    quill.getModule('toolbar').addHandler('image', function (value) {
      console.log("handleImage")
      setShowMediaModal(true);

      //this.quill.format('bold', value)
    });
    quill.getModule('toolbar').addHandler('html', function (value) {
      console.log("handleHTML")
      setShowHtmlModal(true);

      //this.quill.format('bold', value)
    });
    registerToolbarButton({
      quill,
      title: "HTML",
      icon: "&lt;&gt;",
      onClick: () => {
        setEditorHtml(quillRef.current.container.querySelector(".ql-editor").innerHTML);

        setShowHtmlModal(true);
      }});
    registerToolbarButton({
      quill,
      title: "Просмотр",
      icon: "Просмотр",
      onClick: () => {
        setEditorHtml(quillRef.current.container.querySelector(".ql-editor").innerHTML);

        setShowPreviewDesktop(true);
      }});

  }
  const registerDivModule = () => {
    // To allow divs to be inserted into html editor
    // obtained from issue: https://github.com/quilljs/quill/issues/2040
    const Block = Quill.import("blots/block");
    class Div extends Block {}
    Div.tagName = "div";
    Div.blotName = "div";
    Div.allowedChildren = Block.allowedChildren;
    Div.allowedChildren.push(Block);
    Quill.register(Div);
  }
  const handleHtmlSubmit = (data) => {
    console.log("Submit HTML", OutputHTMLParser(data.html));

    quillRef.current.clipboard.dangerouslyPasteHTML(OutputHTMLParser(data.html));

    console.log("HTML",    quillRef.current.root.innerHTML);
    setShowHtmlModal(false);
  }
  const handleSelect = (record) => {
    console.log("handleSelect", record)
    setShowMediaModal(false);
    const imagePath = `/api/asset/files/${record.source}?preset=text&fpx=${record.focalPoint?.x || '0.5'}&fpy=${record.focalPoint?.y || '0.5'}`;
    const range = quillRef.current.getSelection();
    quillRef.current.pasteHTML(range.index, `
<figure class="ql-card-editable ql-card-figure"><img src="${imagePath}"/>${record.authorName ? `<figcaption>${record.authorName}</figcaption>` : ''}</figure>`, 'api');

  }

  return (  <div> <RaRichTextInput {...props}  parse={(val) => {
    return val.replace(/[\r|\n|\r\n]$/, '');
    }}variant={'outlined'} fullWidth={true}  options={RichTextOptions} configureQuill={configureQuill} />
      {showMediaModal && <ModalMedia isShown={true} onClose={() => setShowMediaModal(false)} onSelect={handleSelect}/>}
      {showHtmlModal && <ModalHtmlEditor fullScreen html={editorHtml} isShown={true} onClose={() => setShowHtmlModal(false)} onSubmit={handleHtmlSubmit}/>}
      {showPreviewDesktop && <ModalEditorPreview  html={editorHtml} isShown={true} onClose={() => setShowPreviewDesktop(false)}/>}
    </div>
  );
}
