import * as React from 'react';
import get from 'lodash/get';
import {
  BooleanInput,
  Datagrid,
  ReferenceInput,
  TabbedForm,
  ArrayInput,
  NumberInput,
  SimpleFormIterator,
  FormTab,
  required,
  SelectInput,
  TextInput, FormDataConsumer, Filter, useDataProvider,
} from 'react-admin';
import {
  CasinoGameTestDifficulty, CasinoGameTestDifficultyList,
  CasinoGameTestStrategy,
  CasinoGameTestStrategyList,
  CasinoGameTypeList,
  ICasinoGame
} from 'src/types'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import {useEffect, useState} from 'react'
import {styled} from '@material-ui/core/styles';
import {Card, Box, CardContent, Typography} from '@material-ui/core';
import {ModuleSettingsCard} from 'src/components/inputs/GameModuleSettingsCard'
import MuiAccordion from '@material-ui/core/Accordion';
import {CasinoGameTestTypeList} from 'src/resources/casino-game-test/CasinoGameTestTypeList'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import {useForm} from 'react-final-form'

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon/>}
    {...props}
  />
))(({theme}) => ({
  backgroundColor: 'white',

}));

const GroupHeader = ({game, formKey}: {game: ICasinoGame, formKey: string}) => {
  console.log("GroupHeaderKey", formKey)
  return <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gridGap={'16px'}>
    <BooleanInput source={`${formKey}.enabled`} label={    game.name ?? game.type} helperText={false} />

  </Box>
}
const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  display: 'block'
}));
const SettingsForm = (props) => {
  const dataProvider = useDataProvider()
  const form = useForm()
  const [games, setGames] = useState<ICasinoGame[]>([])
  const [expanded, setExpanded] = useState<string[]>([])
  useEffect(() => {
    dataProvider.getList('casino-game/module-settings', {
      pagination: {page: 1, perPage: 2},
      sort:{ field: 'id', order: 'ASC'},
      filter: {}
    }).then(i => setGames(i.data as ICasinoGame[]))
  }, [])
  const handleToggleAccordion = (key) => {
    if(!expanded.includes(key)){
      setExpanded([...expanded, key])
    }else{
      setExpanded(expanded.filter(i => i !== key))
    }

  }
  const handleSelectAll = () => {
    for(const game of games) {
      const formKey = `gameSettings.${game.type}`
      form.change(`${formKey}.enabled`, true)
    }
  }
  const handleUnSelectAll = () => {
    for(const game of games) {
      const formKey = `gameSettings.${game.type}`
      form.change(`${formKey}.enabled`, false)
    }
  }
  const handleSelectAllModules = () => {
    for(const game of games) {
      const formKey = `gameSettings.${game.type}`
      for(const module of game.modules){
        const moduleKey = `${formKey}.${module.id}`
        form.change(`${moduleKey}.enabled`, true)
      }

    }
  }
  const handleUnSelectAllModules = () => {
    for(const game of games) {
      const formKey = `gameSettings.${game.type}`
      for(const module of game.modules){
        const moduleKey = `${formKey}.${module.id}`
        form.change(`${moduleKey}.enabled`, false)
      }

    }
  }
  return <Box>
    <Typography>Настройки игр</Typography>
    <Typography variant={'caption'}>Выберите игры и их настройки которые будут участвовать в тесте</Typography>
    <Box mt={4} display={'flex'} flexDirection={'row'} alignItems={'center'} gridGap={''}>
      <Box flex={1} display={'flex'} flexDirection={'column'}>
        <Typography  variant={'caption'}>Игры</Typography>
        <ButtonGroup>
          <Button size={'small'} onClick={handleSelectAll}>Включить все</Button>
          <Button  size={'small'}  onClick={handleUnSelectAll}>Выключить все</Button>
        </ButtonGroup>
      </Box>
      <Box display={'flex'} flexDirection={'column'} >
        <Typography  variant={'caption'}>Настройки игры</Typography>
        <ButtonGroup>
          <Button  size={'small'}  onClick={handleSelectAllModules}>Включить все</Button>
          <Button  size={'small'}  onClick={handleUnSelectAllModules}>Выключить все</Button>
        </ButtonGroup>
      </Box>
    </Box>
    {games.map(game => {
      const formKey = `gameSettings.${game.type}`

      return (<Box mt={4}>
      <Card>
        <CardContent>
          <GroupHeader  game={game} formKey={formKey}/>
          <FormDataConsumer id="nome">
            {({ formData }) => {
              console.log("FormData", `${formKey}.enabled`,  get(formData,`${formKey}.enabled`))
              return (
            get(formData,`${formKey}.enabled`) && (game?.modules || []).map(i => <ModuleSettingsCard formKey={`${formKey}.${i.id}`}  module={i}/>)
            )}}
          </FormDataConsumer>


        </CardContent>
      </Card>
    </Box>)})}

  </Box>;
}
const InfoTab = props => {
  return (<FormTab
      label="Информация"
      path={'benefit'}
      {...props}>
    <TextInput source={'name'} label={'Название'} variant={'outlined'} fullWidth={true} />
    <SelectInput source={'strategy'} label={'Стратегия'} defaultValue={CasinoGameTestStrategy.Random} choices={CasinoGameTestStrategyList} variant={'outlined'} fullWidth={true} />
    <NumberInput source={'totalBets'} defaultValue={1000}  label={'Кол-во ставок'} variant={'outlined'} fullWidth={true} />
    <NumberInput source={'totalUsers'} defaultValue={100} label={'Кол-во пользователей'} variant={'outlined'} fullWidth={true} />
    <NumberInput source={'minBet'} defaultValue={1} label={'Минимальная ставка'} variant={'outlined'} fullWidth={true} />
    <NumberInput source={'maxBet'} defaultValue={10}  label={'Максимальная ставка'} variant={'outlined'} fullWidth={true} />
    <NumberInput source={'minBalance'} defaultValue={1} label={'Минимальный баланс'} variant={'outlined'} fullWidth={true} />
    <NumberInput source={'maxBalance'} defaultValue={100}  label={'Максимальный баланс'} variant={'outlined'} fullWidth={true} />
    <TextInput source={'minMultiplier'} defaultValue={1} label={'Минимальный коэфециент'} helperText={'Минимальный коэфециент при котором игрок заберет ставку в многоходовых играх'} variant={'outlined'} fullWidth={true} />
    <TextInput source={'maxMultiplier'} defaultValue={100}  label={'Максимальный коэфециент'} helperText={'Максимальный коэфециент при котором игрок заберет ставку в многоходовых играх'} variant={'outlined'} fullWidth={true} />
    <TextInput source={'minTurns'} defaultValue={1} label={'Минимальное кол-во ходов'} helperText={'Минимальное кол-во ходов в многоходовых играх'} variant={'outlined'} fullWidth={true} />
    <TextInput source={'maxTurns'} defaultValue={100}  label={'Максимальное кол-во ходов'} helperText={'Максимальное кол-во ходов в многоходовых играх'} variant={'outlined'} fullWidth={true} />
    <SelectInput source={'difficulty'} label={'Уровень сложности'} defaultValue={CasinoGameTestDifficulty.None} choices={CasinoGameTestDifficultyList}  helperText={'Для многоходовых игр (Mines, stairs, Towers). Кол-во мин которое выбирает игрок чтобы получать больший коэфециент'} variant={'outlined'} fullWidth={true} />
    {!props.isEdit && <SettingsForm/>}
  </FormTab>)
}




const AviatorTab = props => {
  return (<FormTab
      label="Настройки авиатора"
      path={'aviator'}
      {...props}>
    <TextInput source={'aviator.totalRounds'} defaultValue={1400} label={'Кол-во раундов'} helperText={''} variant={'outlined'} fullWidth={true} />

    <TextInput source={'aviator.minBets'} defaultValue={1} label={'Мин. кол-во ставок за раунд'} helperText={''} variant={'outlined'} fullWidth={true} />
    <TextInput source={'aviator.maxBets'} defaultValue={12} label={'Макс. кол-во ставок авиатора'} helperText={''} variant={'outlined'} fullWidth={true} />

    <TextInput source={'aviator.minAutoStopMultiplier'} defaultValue={1.6} label={'Мин. Авто стоп коэфециент'} helperText={''} variant={'outlined'} fullWidth={true} />
    <TextInput source={'aviator.maxAutoStopMultiplier'} defaultValue={3} label={'Макс. Авто стоп коэфециент'} helperText={''} variant={'outlined'} fullWidth={true} />
    <TextInput source={'aviator.minAutoStop'} defaultValue={1} label={'Мин. кол-во ставок Авто стоп коэфецентов'} helperText={'Должно быть в диапазон мин макс ставок авиатора.'} variant={'outlined'} fullWidth={true} />
    <TextInput source={'aviator.maxAutoStop'} defaultValue={3} label={'Макс. кол-во ставок Авто стоп коэфецентов'} helperText={'Должно быть в диапазон мин макс ставок авиатора. 0 если не использовать автоставки'} variant={'outlined'} fullWidth={true} />
    <TextInput source={'aviator.minFinish'} defaultValue={1} label={'Мин. кол-во ставок, которые попытаются забрать игроки'} helperText={'Должно быть в диапазон мин макс ставок авиатора'} variant={'outlined'} fullWidth={true} />
    <TextInput source={'aviator.maxFinish'} defaultValue={3} label={'Макс. кол-во ставок, которые попытаются забрать игроки'} helperText={'Должно быть в диапазон мин макс ставок авиатора'} variant={'outlined'} fullWidth={true} />
    <TextInput source={'aviator.minFinishMultiplier'} defaultValue={1.2} label={'Мин. коэфециент, которые попытаются забрать игроки'} helperText={''} variant={'outlined'} fullWidth={true} />
    <TextInput source={'aviator.maxFinishMultiplier'} defaultValue={5} label={'Макс. коэфециент, которые попытаются забрать игроки'} helperText={''} variant={'outlined'} fullWidth={true} />

    <BooleanInput source={'aviator.hasLeader'} defaultValue={true} label={'Есть лидеры'} helperText={'Будут ли ставки тех кто заходит с очень большой ставкой'} variant={'outlined'} fullWidth={true} />
    <FormDataConsumer fullWidth={true} variant={'outlined'}>
      {({ formData, ...rest }) => formData.aviator.hasLeader && (
          <TextInput source={'aviator.minLeader'} defaultValue={1} label={'Мин. кол-во лидеров'} helperText={''} variant={'outlined'} fullWidth={true} />
      )}
    </FormDataConsumer>
    <FormDataConsumer fullWidth={true} variant={'outlined'}>
      {({ formData, ...rest }) => formData.aviator.hasLeader && (
          <TextInput source={'aviator.maxLeader'} defaultValue={2} label={'Макс. кол-во лидеров'} helperText={''} variant={'outlined'} fullWidth={true} />

      )}
    </FormDataConsumer>
    <FormDataConsumer fullWidth={true} variant={'outlined'}>
      {({ formData, ...rest }) => formData.aviator.hasLeader && (
          <TextInput source={'aviator.minLeaderBet'} defaultValue={50} label={'Мин. ставка лидера'} helperText={''} variant={'outlined'} fullWidth={true} />

      )}
    </FormDataConsumer>
    <FormDataConsumer fullWidth={true} variant={'outlined'}>
      {({ formData, ...rest }) => formData.aviator.hasLeader && (
          <TextInput source={'aviator.maxLeaderBet'} defaultValue={100} label={'Макс. ставка лидера'} helperText={''} variant={'outlined'} fullWidth={true} />

      )}
    </FormDataConsumer>
    <FormDataConsumer fullWidth={true} variant={'outlined'}>
      {({ formData, ...rest }) => formData.aviator.hasLeader && (
          <TextInput source={'aviator.leaderExistsChance'} defaultValue={25} label={'Шанс что лидер будет в раунде %'} helperText={'Тоесть как часто лидер будет появляется в раунде, если 50% то в кажждом втором раунде будут ставки лидера(ов)'} variant={'outlined'} fullWidth={true} />

      )}
    </FormDataConsumer>

  </FormTab>)
}

const TestTypeTab = props => {
  return (<FormTab
    label="Игры"
    path={'games'}
    {...props}>
   <CasinoGameTestTypeList/>
  </FormTab>)
}

export const CasinoGameTestForm = props => {
  return (
    <TabbedForm {...props} redirect={'list'}>
      <InfoTab isEdit={props.isEdit}/>
      <AviatorTab/>
      {props.isEdit && <TestTypeTab />}
    </TabbedForm>
  );
};
