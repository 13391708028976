import * as React from 'react';
import {FC} from 'react';
import {
  Filter,
  List,
  TextInput,
  TextField,
  ListProps,
  Datagrid,
  FunctionField,
  BooleanInput, DateField, SimpleForm, SelectInput, SelectField, Tab, ReferenceField, ReferenceInput, AutocompleteInput
} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import TransTextField from 'src/components/fields/TransTextField'
import {csvExporter, formatCsvDate, formatListValue} from 'src/utils/exporter'
import {getTranslation} from 'src/utils/translation'
import EmptyList from 'src/components/list/EmptyList'
import LinkField from 'src/components/fields/LinkField'

import {formatPhone, formatUserFirstLastName} from 'src/utils/formatters'
import {AdminRole} from 'src/types'


const exporter = posts => {
  return csvExporter({
    columns: {
      'id': 'ID',
      'phone': 'Телефон',
      'name': 'Имя',
      'organization': 'Организация',
      'role': 'Роль',
      'createdAt': 'Создано',

    },
    data:  posts.map(item => ({
      ...item,
      phone: formatPhone(item.phone),
      name: formatUserFirstLastName(item),
      organization: item.organization?.name,
      createdAt:  formatCsvDate(item.createdAt),
      updatedAt:  formatCsvDate(item.updatedAt)
    })),
    fileName: 'Users'
  })
};

const _Filter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'} />
    <TextInput source="partnerUserId" label={'Внутренний ID'} variant={'outlined'} />
    <TextInput source="login||$contL" label={'Телефон'}  variant={'outlined'}  resettable={true}/>

  </Filter>
)

const UserList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={exporter}
      bulkActionButtons={false}
      filters={<_Filter/>}
      title="Пользователи"
      sort={{field: 'id', order: 'DESC'}}
      empty={<EmptyList title={'Нет Пользователей'} />}
    >
    <Datagrid >
      <IdShowButtonField source={'id'} label={'ID'}/>
      <TextField source="login" label={'Логин'}/>
      <TextField source="partnerUserId" label={'Внутренний ID'}/>
      <FunctionField source="balance" label={'Баланс'} render={i => `${i.profit} ${i.currency}`}/>
      {(props.permissions === AdminRole.SuperAdmin || props.permissions === AdminRole.Admin)  && <ReferenceField label="Партнер" source="accountId" reference="account">
          <TextField source="name"  />
      </ReferenceField>}
      <DateField source="createdAt" label={'Регистрация'} showTime={true} />

    </Datagrid>
    </List>
  );
}

export default UserList;
