import {ModuleSettings, ModuleSettingsOption} from 'src/types'
import {BooleanInput, NumberInput, TextInput} from 'react-admin'
import {Box, Card, CardContent, Typography} from '@material-ui/core'
import * as React from 'react'

const renderInput = (key: string, module: ModuleSettings, option: ModuleSettingsOption) => {
  const source = `${key}.${option.id}`;
  console.log("renderInput", source, module, option)
  switch (option.type) {
    case 'number':
      return <NumberInput source={source} label={option.name} defaultValue={option.defaultValue}
                          helperText={option.description} variant={'outlined'} fullWidth/>
    case 'text':
    default:
      return <TextInput source={source} label={option.name} defaultValue={option.defaultValue}
                        helperText={option.description} variant={'outlined'} fullWidth/>
  }
}
export const ModuleSettingsCard = ({ module, formKey}: { formKey: string, module: ModuleSettings }) => {
  console.log("ModuleSettingsCardKey", formKey)
  return (<Box mt={2}>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h6" gutterBottom component="div">
            {module.name}
          </Typography>
          <Typography variant="body2" gutterBottom component="div">
            {module.description}
          </Typography>
          <BooleanInput source={`${formKey}.enabled`} label={'Включено'} variant={'outlined'}/>

          {module.settings.map(i => <Box>{renderInput(formKey, module, i)}</Box>)}
        </CardContent>
      </Card>
    </Box>
  )
}