import React, {useEffect} from 'react'
import {Admin, Resource, DataProvider} from 'react-admin'

import LoginPage from "./resources/login";
import {AuthProvider} from "src/common/providers/AuthProvider";
import {dataProvider} from "src/common/providers/dataProvider";
// @ts-ignore

import russianMessages from 'ra-language-russian'
import polyglotI18nProvider from 'ra-i18n-polyglot'

import {theme} from 'src/style'
import {Route} from 'react-router-dom';
import administrators from 'src/resources/administrators'
import user from 'src/resources/user'
import casinoGameTests from 'src/resources/casino-game-test'
import userTransactions from 'src/resources/user-transaction'
import asset from 'src/resources/assets'
import account from 'src/resources/account'
import casinoGame from 'src/resources/casino-game'
import casinoGameAccount from 'src/resources/casino-game-account'
import casinoGameRound from 'src/resources/casino-game-round'
import aviatorGameRound from 'src/resources/aviator-game-round'
import aviatorGameRoundBet from 'src/resources/aviator-game-round-bet'
import chessGameBoard from 'src/resources/chess-game-board'
import chessGameAccountSettings from 'src/resources/chess-game-account-settings'
import settings from 'src/resources/settings'
import page from 'src/resources/page'
import {AccountApi} from 'src/resources/account/AccountApi'
import {Layout} from 'src/common/layout'
import {AdminRole} from 'src/types'
import casinoGameRoundReport from "src/resources/casino-game-round-report";

const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru')


const App = () => {
    return (
        <Admin
            theme={theme}
            i18nProvider={i18nProvider}
            dataProvider={dataProvider}
            authProvider={AuthProvider}
            loginPage={LoginPage}
            layout={Layout}
            customRoutes={[
                <Route
                    key="account-api"
                    path="/account-api"
                    component={AccountApi}
                />
            ]}
        >

            {permissions => {
                return [
                    // <Resource name="casino-game-account" {...casinoGameAccount} options={{label: 'Настройки игр'}}/>,

                    <Resource name="casino-game-round" {...casinoGameRound} options={{label: 'Ставки'}}/>,
                    <Resource name="casino-game-round-report" {...casinoGameRoundReport} options={{label: 'Ставки Отчет'}}/>,
                    <Resource name="user" {...user} options={{label: 'Пользователи'}}/>,
                    <Resource name="user-transaction" {...userTransactions} options={{label: 'Транзакции'}}/>,
                    <Resource name="chess-game-board" {...chessGameBoard} options={{label: 'Шахматы Партии'}}/>,
                    <Resource name="chess-game-account-settings" {...chessGameAccountSettings}
                              options={{label: 'Шахматы Настройки'}}/>,
                    <Resource name="casino-game/module-settings"/>,

                    ...((permissions === AdminRole.SuperAdmin || permissions === AdminRole.Admin) ? [
                        <Resource name="aviator-game-round" {...aviatorGameRound} options={{label: 'Авиатор Раунды'}}/>,
                        <Resource name="aviator-game-round-bet" {...aviatorGameRoundBet}
                                  options={{label: 'Авиатор Ставки'}}/>,

                        <Resource name="casino-game" {...casinoGame} options={{label: 'Игры (контент)'}}/>,
                        <Resource name="casino-game-test" {...casinoGameTests} options={{label: 'Тесты игр'}}/>,
                        <Resource name="casino-game-type-test"/>,
                        <Resource name="settings" {...settings} options={{label: 'Настройки'}}/>,

                        <Resource name="page" {...page} options={{label: 'Текста'}}/>,
                        <Resource name="account" {...account} options={{label: 'Партнеры'}}/>,
                        <Resource name="admin" {...administrators} options={{label: 'Админы'}}/>,
                    ] : [])
                ];
            }
            }


        </Admin>
    )
}

export default App
