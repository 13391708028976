import * as React from 'react';
import {FC, useEffect, useRef} from 'react';
import {
  Filter,
  List,
  TextInput,
  ReferenceArrayInput,
  AutocompleteInput,
  ReferenceInput,
  ListProps,
  AutocompleteArrayInput,
  BooleanField,
  ArrayField,
  FunctionField,
  Datagrid,
  ReferenceField,
  ChipField,
  SingleFieldList,
  SelectInput,
  TextField,
  SelectField,
  BooleanInput, DateField, SimpleForm, useRefresh, FormTab
} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {csvExporter, formatCsvDate, formatListValue} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import RelatedList from 'src/components/list/RelatedList'
import {CasinoGameTestActionButton} from 'src/resources/casino-game-test/CasinoGameTestActionButton'
import {
  CasinoGameTestDifficultyList,
  CasinoGameTestStatus,
  CasinoGameTestStatusList,
  CasinoGameTestStrategy,
  CasinoGameTestStrategyList
} from 'src/types'
import {CasinoGameTestNameField} from 'src/resources/casino-game-test/CasinoGameTestName'
import ProfitField from 'src/components/fields/ProfitField'
import {convertFiat} from 'src/utils/formatters'

const exporter = posts => {
  return csvExporter({
    columns: {
      'id': 'ID',
      'createdAt': 'Создан',

    },
    data:  posts.map(item => ({
      ...item,
      createdAt:  formatCsvDate(item.createdAt),
    })),
    fileName: 'CasinoGameTest'
  })
};

const _Filter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'} />
  </Filter>
)


const CasinoGameTestDataGrid = props => {
  const refresh = useRefresh();
  const refreshRef = useRef(null);
  useEffect(() => {
    if(props.page !== 1){
      return;
    }
    refreshRef.current = setInterval(() => refresh(), 10 * 1000);
    return () => {
      if( refreshRef.current){
        clearInterval( refreshRef.current)
      }
    }
  }, [props.page])
  return (<Datagrid {...props}>
    <IdShowButtonField source={'id'} label={'ID'}/>
    <CasinoGameTestNameField source={'status'} label={'Статус'}/>
    <SelectField source={'strategy'} label={'Стратегия'} choices={CasinoGameTestStrategyList} />
    <ProfitField source={'totalBetAmount'} label={'Ставок'} type={'bet'}/>
    <ProfitField source={'totalProfitAmount'} label={'Выиграно'} type={'win'} />
    <ProfitField source={'totalLoseAmount'} label={'Проиграно'} type={'lose'} />
    <TextField source={'totalWin'} label={'Выигрыши'} />
    <TextField source={'totalLose'} label={'Проигрыши'} />
    <ProfitField  label={'GGR'} value={(i) => i.totalBetAmount - i.totalProfitAmount} currency={'USD'} type={'bet'}/>
    <FunctionField  label={'RTP'} render={(i) =>  i.totalBetAmount ? `${convertFiat(i.totalProfitAmount / i.totalBetAmount * 100)}%` : ''}/>
    <SelectField source={'difficulty'} label={'Сложность'} choices={CasinoGameTestDifficultyList} />

    <DateField source="createdAt" label={'Создан'} showTime={true} />
    <CasinoGameTestActionButton size={'small'}/>
  </Datagrid>)
}

const CasinoGameTestList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={exporter}
      bulkActionButtons={false}
      filters={<_Filter/>}
      title="Тесты"
      sort={{field: 'id', order: 'DESC'}}
      empty={<EmptyList title={'Нет тестов'} description={'Вы можете добавить тест'} buttonText={'Добавить тест'}/>}
    >
    <CasinoGameTestDataGrid/>
    </List>
  );
}

export default CasinoGameTestList;
