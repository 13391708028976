import React, {useState} from 'react';
import {
  BooleanInput,
  required,
  TextInput, useDataProvider, useRefresh,
  ArrayInput, SimpleFormIterator, ReferenceInput, AutocompleteInput, Confirm, useNotify
} from "react-admin";


import {Button} from '@material-ui/core'
import {CasinoGameTestStatus} from 'src/types'

export const CasinoGameTestActionButton = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const record = props.record ?? props.data;

  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const isFinished = [CasinoGameTestStatus.Finished, CasinoGameTestStatus.Cancelled, CasinoGameTestStatus.Error].includes(record?.status);
  const inProgress = [CasinoGameTestStatus.InQueue, CasinoGameTestStatus.InProgress].includes(record?.status);
  const getTitle = () => {
    if(isFinished){
      return `Перезапустить тест #${record.id}`
    } else if(inProgress){
      return `Остановить тест #${record.id}`
    }else{
      return `Запустить тест #${record.id}`
    }
  }
  const getDescription = () => {
    if(isFinished){
      return `Вы уверены что хотите перезапустить тест #${record.id}`
    } else if(inProgress){
      return `Вы уверены что хотите остановить тест #${record.id}`
    }else{
      return `Вы уверены что хотите запустить тест #${record.id}`
    }
  }
  const getButtonTitle = () => {
    if(isFinished){
      return `Перезапустить`
    }else if(inProgress){
      return `Остановить`
    }else{
      return `Запустить`
    }
  }
    const handleSubmit = async () => {
      setLoading(true);
      try {
        if(inProgress){
          await dataProvider.create(`casino-game-test/${record.id}/cancel`, {data: {}})
        }else{
          await dataProvider.create(`casino-game-test/${record.id}/start`, {data: {}})
        }

        await refresh();
        setIsOpen(false);
      }catch (error) {
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
            ? 'Ошибка'
            : error.message,
          'warning'
        );
      }

      setLoading(false);
      return true;
    };

  return <div>
    <Button size={props.size ?? 'medium'} variant={'contained'} color={ 'primary'} onClick={() => setIsOpen(true)}>{getButtonTitle()}</Button>
    <Confirm
      isOpen={isOpen}
      loading={loading}
      title={getTitle()}
      content={getDescription()}
      onConfirm={handleSubmit}
      onClose={() => setIsOpen(false)}
    />
  </div>
}
