import * as React from 'react';
import {FC} from 'react';
import {
    Filter,
    List,
    TextInput,
    ReferenceArrayInput,
    AutocompleteInput,
    ReferenceInput,
    ListProps,
    AutocompleteArrayInput,
    BooleanField,
    ArrayField,
    FunctionField,
    Datagrid,
    ReferenceField,
    ChipField,
    SingleFieldList,
    SelectInput,
    TextField,
    SelectField,
    BooleanInput, DateField, SimpleForm
} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {csvExporter, formatCsvDate, formatListValue} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'

import ProfitField from 'src/components/fields/ProfitField'
import VisibilityField from 'src/components/fields/VisibilityField'
import {AdminRole, CasinoGameRoundStatusList, CasinoGameTypeList} from 'src/types'
import {convertFiat, formatAmount} from 'src/utils/formatters'
import DateRangeFilterInput from "src/components/inputs/DateRangeFilterInput";
import DateRangeInput from "src/components/inputs/DateRangeInput";

const exporter = posts => {
    return csvExporter({
        columns: {
            'createdAt': 'Время',
            'currency': 'Валюта',
            'partnerUserId': 'ID Игрока',
            'gameType': 'Игра',
            'status': 'Статус',
            'wagerUsd': 'Ставка',
            'multiplier': 'Коэффициент',
            'profitUsd': 'Коэффициент',
            'ggr': 'GGR',
            'rtp': 'RTP',
            'isDemo': 'Демо',
        },
        data: posts.map(item => ({
            ...item,
            rtp: `${(item.profitUsd / item.wagerUsd * 100).toFixed(2)}%`,
            ggr: formatAmount(item.wagerUsd - item.profitUsd, item.currency),
            wagerUsd: formatAmount(item.wagerUsd, item.currency),
            profitUsd: formatAmount(item.profitUsd > 0 ? item.profitUsd : -1 * item.wager, item.currency),
            multiplier: item?.multiplier.toFixed(2),
            partnerUserId: item?.user.partnerUserId,
            gameType: formatListValue(item.gameType, CasinoGameTypeList),
            status:  formatListValue(item.status,CasinoGameRoundStatusList),
            isDemo: item.isDemo ? 'Демо' : '',
            createdAt: formatCsvDate(item.createdAt)
        })),
        fileName: 'CasinoGameRound'
    })
};

const _Filter = (props) => (
    <Filter {...props}>
        <TextInput source="id" label={'ID'} variant={'outlined'}/>
        <SelectInput source="gameType" label={'Игра'} choices={CasinoGameTypeList} variant={'outlined'}
                     alwaysOn={true}/>
        <DateRangeInput source={'createdAt'} sourceStart={'createdAt||$gte'} sourceEnd={'createdAt||$lte'} label="Даты"
                        alwaysOn={true}/>
        <BooleanInput source="isDemo" label={'Демо'} variant={'outlined'} resettable={true} alwaysOn={true}/>

        <TextInput source="wagerUsd||$gte" label={'Ставка от'} variant={'outlined'}/>
        <TextInput source="wagerUsd||$lte" label={'Ставка до'} variant={'outlined'}/>
        <TextInput source="multiplier||$gte" label={'Коэффециент от'} variant={'outlined'}/>
        <TextInput source="multiplier||$lte" label={'Коэффециент до'} variant={'outlined'}/>
        <TextInput source="profitUsd||$gte" label={'Выигрыш от'} variant={'outlined'}/>
        <TextInput source="profitUsd||$lte" label={'Выигрыш до'} variant={'outlined'}/>
        <TextField source={'user.partnerUserId'} label={'ID игрока'}/>
        <SelectInput source="status" label={'Статус'} choices={CasinoGameRoundStatusList} variant={'outlined'}/>
    </Filter>
)

const CasinoGameRoundList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            exporter={exporter}
            bulkActionButtons={false}
            filters={<_Filter/>}
            title="Ставки"
            filterDefaultValues={{isDemo: false}}
            sort={{field: 'createdAt', order: 'DESC'}}
            empty={<EmptyList title={'Нет ставок'} description={'Вы можете добавить ставку'}
                              buttonText={'Добавить ставку'}/>}
        >
            <Datagrid>
                <DateField source="createdAt" label={'Время'} showTime={true}/>
                <TextField source="currency" label={'Валюта'}/>
                {(props.permissions === AdminRole.SuperAdmin || props.permissions === AdminRole.Admin) &&
                    <ReferenceField label="Партнер" source="accountId" reference="account">
                        <TextField source="name"/>
                    </ReferenceField>}
                <TextField source={'id'} label={'ID ставки'}/>
                <TextField source={'user.partnerUserId'} label={'ID Игрока'}/>
                <SelectField source="gameType" label={'Игра'} choices={CasinoGameTypeList}/>
                <SelectField source="status" label={'Статус'} choices={CasinoGameRoundStatusList}/>
                <ProfitField source="wagerUsd" label={'Ставка'} currency={'USD'} type={'bet'}/>
                <FunctionField source="multiplier" render={i => i?.multiplier.toFixed(2)} label={'Коэффициент'}/>
                <ProfitField source="profitUsd" label={'Выигрыш'}
                             value={i => i.profitUsd > 0 ? i.profitUsd : -1 * i.wagerUsd} currency={'USD'}/>
                <ProfitField label={'GGR'} value={(i) => i.wagerUsd - i.profitUsd} currency={'USD'} type={'bet'}/>
                <FunctionField label={'RTP'} render={(i) => `${(i.profitUsd / i.wagerUsd * 100).toFixed(2)}%`}/>
                <FunctionField source="isDemo" label={'Демо'} render={i => i.isDemo ? 'Демо' : ''}/>

            </Datagrid>
        </List>
    );
}

export default CasinoGameRoundList;
