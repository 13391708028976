import * as React from 'react';

import {
  BooleanInput,
  Datagrid,
  DateField,
  SimpleForm,
  ReferenceInput,
  SimpleFormIterator,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  SelectInput,
  TextInput, useDataProvider, FormDataConsumer, ReferenceArrayInput, AutocompleteArrayInput, ArrayInput, DateInput,
} from 'react-admin';

import {MediaInput} from 'src/components/inputs/MediaInput'
import {RichTextInput} from 'src/components/inputs/RichTextInput'
import {AdminRole, AdminRoleList} from 'src/types'


export const AdministratorForm = props => {
  return (
    <SimpleForm {...props} redirect={'list'}>

      {(props.permissions === AdminRole.SuperAdmin || props.permissions === AdminRole.Admin)  &&
      <ReferenceInput label="Партнер" source="accountId" reference="account" variant={'outlined'} validate={required()} fullWidth={true} perPage={100}>
          <SelectInput optionText="name"/>
      </ReferenceInput >}

      <SelectInput
        source="role"
        label={'Роль'}
        choices={AdminRoleList}
        fullWidth
        variant={'outlined'}
        validate={requiredValidate}
      />
      <TextInput
        source="email"
        label={'Email'}
        fullWidth
        variant={'outlined'}
        validate={requiredValidate}
      />
      <TextInput
        source="name"
        label={'Имя'}
        fullWidth
        variant={'outlined'}
      />

      <TextInput
        source="setPassword"
        label={'Пароль'}
        variant={'outlined'}
        fullWidth
      />

    </SimpleForm>
  );
};

const requiredValidate = [required()];